<template>
  <div class="productSwiper">
    <div class="big">
      <changeSwiper :options="mainoptions" ref="swiper1" @change="change">
        <changeSwiperItem v-for="item in goodDetilData.images" :key="item">
          <img v-lazy="item" alt="" />
        </changeSwiperItem>
      </changeSwiper>
    </div>

    <div class="minpic" ref="minpic">
      <changeSwiper
        :options="mainoptions2"
        className="newwiper"
        ref="swiper2"
        :ishidden="true"
      >
        <changeSwiperItem v-for="(item,index) in goodDetilData.images" :key="item">
          <img @click="goto(index)" v-lazy="item" alt="" :class="{imgactive:num==index}" />
        </changeSwiperItem>
      </changeSwiper>
    </div>

  </div>
</template>
<script>

import ChangeSwiper from "common/changeSwiper/ChangeSwiper";
import ChangeSwiperItem from "common/changeSwiper/ChangeSwiperItem";

export default {
  data() {
    return {
      //主配置项
      mainoptions: {
        // loop: true,
        observer: true,
        preloadImages: true,
        updateOnImagesReady : true,
      },
      mainoptions2: {
        // loop: true,
        // prevButton: ".swiper-button-prev",
        // nextButton: ".swiper-button-next",
        slidesPerView: 5,
        slidesPerGroup: 1,
        centeredSlides: true,
        preloadImages: true,
        updateOnImagesReady : true,
        spaceBetween: 10,
        observer: true,
      },
      num:0,
      start:0
    };
  },

  computed: {},
  mounted() {
    this.control();
  },
  methods: {
    //轮播图双向绑定
    control() {
      this.$refs.swiper1.mySwiper.params.control = this.$refs.swiper2.mySwiper;
      this.$refs.swiper2.mySwiper.params.control = this.$refs.swiper1.mySwiper;
    },

    //跳转到对应图片
    goto(index) {
      this.$refs.swiper1.mySwiper.slideTo(index, 300, false);
    },

    //监听index
    change(index){
      if(this.start==0){
        // this.control()
        this.start = 1
      }
      this.num = index
    }

  },
  props:{
    goodDetilData:{
      type:Object
    }
  },
  components: { ChangeSwiper, ChangeSwiperItem },
  created() {},
};
</script>
<style lang='scss' scoped>

.productSwiper {
  overflow: hidden;
  .slick-track {
    ul {
      display: flex;
      justify-content: center;
      li {
        list-style: none;
        max-width: 20%;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border: 1px solid var(--border-color-default);
        img {
          width: 100%;
        }
      }
    }
  }
}

.minpic {
  margin-top:10px;
  position: relative;
  box-sizing: border-box;
  img {
    box-sizing: border-box;
    border: 1px solid var(--border-color-default);
    cursor: pointer;
  }

  .imgactive{
    border: 1px solid rgb(88, 88, 88);

  }

 
}
</style>