import Dialog from './Dialog'
export default{
    install:function(Vue){
       const myDialog = Vue.extend(Dialog)
       Vue.prototype.$createDialog = function(type,context_this){
           let ele = new myDialog({
               el:document.createElement('div'),
               data(){
                   return {
                       type,
                       context_this
                   }
               }
           })

           document.body.appendChild(ele.$el)
       }
    }

}