<template>
  <div class="pulldown" ref="pulldown">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="center"  @click="showMenu">
      <slot name="center"></slot>
    </div>
    <div class="right"  @click="showMenu">
      <slot name="right"></slot>
    </div>
    <div class="menu" v-show="isshowMenu" ref="menu">
      <slot name="menu"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name:'Pulldown',
  data() {
    return {

    };
  },
  methods:{
    //点击后显示下拉菜单
    showMenu(){
      this.$emit('showMenu',!this.isshowMenu)
    },
    //获取并设置下拉菜单的偏移量
    getExcursion(){
      let h = this.$refs.pulldown.offsetHeight
      this.$refs.menu.style.top = h + 'px'
    },


  },

  mounted(){
    this.getExcursion()

  },

  props:{
    //是否显示下拉菜单
     isshowMenu:{
       type:Boolean,
       default:false
     },


  },
  created() {},
};
</script>
<style lang='scss' scoped>

.pulldown{
    display: flex;
    align-items: center;
    position: relative;

    .menu{
      position: absolute;
      z-index:22;
      background-color: white;
      box-shadow: 0 0 6px rgba(0,0,0,.3);
    }

    &:hover{
      cursor: pointer;
    }
}


</style>