/*会员/用户相关 */
var U = require("../utils/ajax.js");
var Passport={
	/**
	 * @param {Object} opt username
	 */
	Captcha:function(opt){
	   return U.ajax({
	        service: 'user.captcha',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
	    });
	},
	Register:function(opt){
	   return U.ajax({
	        service: 'user.register',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
	    });
	},
    SigunUp:function(opt){
       return U.ajax({
            service: 'user.registerForApp',
            data: {
                mobile:opt.data.username,
				password:opt.data.password,
				repeat_password:opt.data.password,
				invitation_code:opt.data.invitation_code,
				email:opt.data.email,
				r_member_id:opt.data.r_member_id,
				open_id:opt.data.open_id,
				union_id:opt.data.union_id,
				bindtype:opt.data.bindtype,
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
        });
    },
    Login:function(opt){
       return U.ajax({
            service: 'user.login',
            data: {
				account:opt.data.account,
				password:opt.data.password
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail(res,all)
            },
            context_this:opt.context_this
        });
    },
    SendVer:function(opt){
       return U.ajax({
            service: 'ems.send',
            data: {
				email:opt.data.email,
                event:opt.data.event,
                mobile:opt.data.mobile
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    Resetpwd:function(opt){
       return U.ajax({
            service: 'user.resetpwd',
            data: {
				type:opt.data.type,
                captcha:opt.data.captcha,
                newpassword:opt.data.newpassword,
                email:opt.data.email,
                mobile:opt.data.mobile
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
}
var Info={
    Detail:function(opt){
       return U.ajax({
            service: 'user.info',
            data: {
                addr_id:opt.data.addr_id
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    ChangeEmail:function(opt){
       return U.ajax({
            service: 'user.changeemail',
            data: {
                captcha:opt.data.captcha,
                email:opt.data.email
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    ChangePassword:function(opt){
       return U.ajax({
            service: 'user.changePassword',
            data: {
                old_password:opt.data.old_password,
                new_password:opt.data.new_password,
                repeat_password:opt.data.repeat_password
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },


}
var Addr={
    List:function(opt){
       return U.ajax({
            service: 'user.getAllAddr',
            data: {
                opt:opt.data
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this,
        });
    },
	//设置默认地址
			// 本身就是键值对了？？
	setDefAddr:function(opt){
	   return U.ajax({
	        service: 'user.setDefAddr',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
            context_this:opt.context_this
	    });
	},
    Detail:function(opt){
       return U.ajax({
            service: 'user.getAddr',
            data: {
                addr_id:opt.data.addr_id
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    Delete:function(opt){
       return U.ajax({
            service: 'user.delAddr',
            data: {
                addr_id:opt.data.addr_id
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    getDef:function(opt){
       return U.ajax({
            service: 'user.getDefAddr',
            data: {
                nowaddr:opt.data.nowaddr,
            },
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    Save:function(opt){
       return U.ajax({
            service: 'user.editAddr',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    }
}
var Fav={
    List:function(opt){
       return U.ajax({
            service: 'user.favList',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    Fav:function(opt){
       return U.ajax({
            service: 'goods.fav',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail(res,all);
            },
            context_this:opt.context_this
        });
    },
}
//用户推荐商品
var recommendGoods={
	Index:function(opt){
	   return U.ajax({
	        service: 'user.recommendGoods',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail(res,all);
	        },
	    });
	},
}

var Order={
    List:function(opt){
       return U.ajax({
            service: opt.data.frompintuan?'user.pintuan_orders':'user.orders',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    },
    Detail:function(opt){
        opt.data.order_id= opt.data.order_id;
       return U.ajax({
            service: 'user.orderDetail',
            data: opt.data,
            succ: function (res, all) {
                opt.succ(res,all);
            },
            fail:function (res,all) {
                opt.fail?opt.fail(res,all):'';
            },
            context_this:opt.context_this
        });
    }
}
var Coupon={
	List:function(opt){
	   return U.ajax({
	        service: 'user.coupons',
	        data: {
	            type:opt.data.type?opt.data.type:'active',
				page:opt.data.page?opt.data.page:'1',
				// limit:10,
                limit:opt.data.limit
	        },
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
            context_this:opt.context_this
	    });
	},
	ShowCouponByPoint:function(opt){
	   return U.ajax({
	        service: 'user.showCouponByPoint',
	        data: {
	   //          type:opt.data.type?opt.data.type:'active',
				// page:opt.data.page?opt.data.page:'1',
				// limit:10,
	        },
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
	    });
	},
	receiveCoupon:function(opt){
	   return U.ajax({
	        service: 'user.receiveCoupon',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail(res,all);
	        },
	    });
	},
	queryCoupon:function(opt){
	   return U.ajax({
	        service: 'user.queryCoupon',
	        data: opt.data,
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail(res,all);
	        },
	    });
	},
}
var Balance={
	List:function(opt){
	   return U.ajax({
	        service: 'user.balanceLog',
	        data: {
				page:opt.data.page?opt.data.page:'1',
				limit:10,
	        },
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
            context_this:opt.context_this
	    });
	}
}
var Point={
	List:function(opt){
	   return U.ajax({
	        service: 'user.pointLog',
	        data: {
				page:opt.data.page?opt.data.page:'1',
				limit:10,
	        },
	        succ: function (res, all) {
	            opt.succ(res,all);
	        },
	        fail:function (res,all) {
	            opt.fail?opt.fail(res,all):'';
	        },
            context_this:opt.context_this
	    });
	}
}
module.exports = {
	Passport,
    Info,
    Addr,
    Order,
    Fav,
	Coupon,
	recommendGoods,
	Balance,
	Point,
}