import Vue from 'vue'
import Popover from './index.vue'

// 实现提示的单例


Vue.use({
    install: function (Vue) {
        const myPopover = Vue.extend(Popover)
        // 弹框的类名
        let className = 'my_popover'

        // 参数e按钮点击事件明细，用于获取点击的坐标
        Vue.prototype.$myPopover = function (e) {
            // 实现单例，判断当前页面有无此弹框，有则隐藏之前的弹框
            let comArr = [...document.body.querySelectorAll(`.${className}`)]
            if(!!comArr.length){
                comArr.forEach((item)=>{
                    item.style.opacity = 0
                    if(item.$component){
                        item.$component.close()
                    }
                })
            }

            let ele = new myPopover({
                el: document.createElement('div'),
                data(){
                    return {
                        clickDetail:e,
                        className
                    }
                }
            })
            document.body.appendChild(ele.$el)
            ele.$el.$component = ele

        }
    }

})