// 处理组件公共数据
const common_data = {
    state: {
        header_menu:{},
        foot_menu: {}
    },

    mutations: {
        updateMenu(state, payload) {

            let header_menu = payload.find((item) => {
                return item.type == 'topmenu'
            })
            let foot_menu = payload.find((item) => {
                return item.type == 'footermenu'
            })

            if(header_menu){
                state.header_menu = header_menu
            }
            if(foot_menu){
                state.foot_menu = foot_menu
            }


        }
    },

}

export default common_data

