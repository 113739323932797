// 收银台路由权限
function paycenter_view(to, from, next) {

    //只可以从结算页、会员中心、订单详情到收银台
/*     if (to.path.startsWith('/paycenter')) {
        if (from.path.startsWith('/check') || from.path.startsWith('/member') || from.path.startsWith('/ordertail')) {
        } else {
            next('/home/index')
        }
    } */


}


export {
    paycenter_view
}