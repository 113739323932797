exports.config = {
	GUID: 'ebbc52df-0cee-404c-88a8-7b228b91d9ae',
	ASEkey: 'qlpomjjpskmiwtok',
	ASEIv: '0612743652727330',
	//SERVER_PRODUCTION: 'http://192.168.1.202/whyshop/weipeng/b2c_wb/public/api'
	//SERVER_PRODUCTION: 'https://xcx.wb0086.com/api',
	// SERVER_PRODUCTION: 'https://newpc.zongzi.uk/api' ,
	SERVER_PRODUCTION: 'https://www.buychinesetea.com/index.php/api',
	// SERVER_PRODUCTION: 'http://v2.b2c.demo.whyshop.cn/index.php/api',
	// SERVER_PRODUCTION: 'https://ketestore.co.uk/index.php/api' ,
	//  SERVER_PRODUCTION: 'https://admin.ketestore.co.uk/index.php/api' ,
	// MAIN_HOST:'localhost:8622',
	//SERVER_PRODUCTION: ' https://newpc.zongzi.uk/index.php/api',
	//SERVER_PRODUCTION: ' https://www.zongzi.uk/api',
	//SERVER_PRODUCTION: 'https://admin.ketestore.co.uk/api' ,
	LANG: 'en',
	//SERVER_PRODUCTION: 'http://192.168.1.111:8625/api' ,
};

