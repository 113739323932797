
const cl = function(key,dataObj,lang){
  let val =  Object.keys(dataObj).find((item)=>{
      return item == key+'_'+lang
  })
  
  if(val && dataObj[val]){
      return dataObj[val]
  }else{
      return dataObj[key]
  } 
}


export default cl

