

// 未登录的情况下从购物车删商品
function del(opt) {

    let allgoods
    // 查看本地有无该商品
    try {
        allgoods = JSON.parse(localStorage.getItem('allgoods'))
        if (!my_utils.isArray(allgoods)) {
            throw 'allgoods is not Array!'
        } 
    } catch (err) {
        console.error(new Error(err));
        // 出现异常则重置allgoods
        allgoods = []
        localStorage.setItem('allgoods', JSON.stringify(allgoods))
    }
    
    // cart_ident 为字符串数组
    let cart_ident = JSON.parse(opt.data.cart_ident)

    
      allgoods = allgoods.filter((item)=>{
        let res = true
        let has = cart_ident.find((val)=>{
            return val == item.cart_ident
        })
        !!has && (res = false)
        return res
      })
    

    localStorage.setItem('allgoods', JSON.stringify(allgoods))

    // 请求购物车数据
    $my_vue.$store
        .dispatch("getCart")
        .then((res) => {
            opt.succ(res)
        })
        .catch((err) => {
            opt.fail(err, err)
        });
}

let method = {

}

let $cart_Update = function (opt) {
    if (opt.data?.service != 'cart.delete') return true
    // 判断用户当前是否已登录
    let isRegister = !!$my_vue.$store.state.user.token

    if (isRegister) {
        return method
    } else {
        del(opt)
        return false
    }

}

export default $cart_Update