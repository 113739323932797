import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cl from './method/changeLangByKey.js'
// import zh from './zh'
// import en from './en'

Vue.use(VueI18n)
Vue.prototype.$cl = cl

let lang = !!localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en'

const i18n = new VueI18n({
  locale:lang,
  messages:{
    zh:require('./zh-cn.json'),
    en:require('./en-us.json')
  },

})


export default i18n
