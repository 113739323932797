<template>
  <div class="time"></div>
</template>
<script>
export default {
  data() {
    return {
      timer:null
    };
  },
  created() {
    this.countTime();
  },

  methods: {
    countTime() {
      //获取当前时间
      let now = new Date();

      //结束时间
      let end = new Date(this.deadline);

      //时间差
      let leftTime = end - now;

      if( leftTime <0){
        this.$emit('stop')
        return {d:0,h:0,m:0,s:0}
      }


        //100%60 40 130%60 10  1%60 59

      //定义变量 d,h,m,s保存倒计时的时间   2000        2s  2000/1000/60  2000/1000/60/60  
      let d, h, m, s;
      if (leftTime >= 0) {
        d =('000'+ Math.floor(leftTime / 1000 / 60 / 60 / 24)).split('');
        h =('000'+ Math.floor((leftTime / 1000 / 60 / 60) % 24)).split('');
        m =('000'+ Math.floor((leftTime / 1000 / 60) % 60)).split('');
        s =('000'+ Math.floor((leftTime / 1000) % 60)).split('');
      }


      d=[d.pop(),d.pop()].reverse().join('')
      h=[h.pop(),h.pop()].reverse().join('')
      m=[m.pop(),m.pop()].reverse().join('')
      s=[s.pop(),s.pop()].reverse().join('')




      this.$emit("update:time", { d, h, m, s });
      //递归每秒调用countTime方法，显示动态时间效果
     this.timer =  setTimeout(this.countTime, 1000);
    },
  },
  props: {
    // 截止时间
    deadline: {
      type: [String,Number],
    },

    //时间对象
    time: {
      type: Object,
    },
  },

  destroyed(){
    clearInterval(this.timer)
  }
};
</script>
<style lang='scss' scoped>
</style>