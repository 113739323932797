<template>
  <div class="ShopFooter">
    <div class="main container">
      <!-- 底部信息 -->
      <footer-info></footer-info>
    </div>
  </div>
</template>
<script>
import { FooterInfo } from "./child";
export default {
  name: "ShopFooter",
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    FooterInfo,
  },
};
</script>
<style lang='scss' scoped>
.ShopFooter {
  background-color: white;
  // border-top:1px solid var(--border-color-default);
}
</style>