<template>
  <div class="NewEmpty" :style="{height:height+'px'}">
      <div class="main">

    <div class="image">
      <div class="imgBox" :style="imgStyle">
        <slot name="img">
          <img :src="require('./img/' + image + '.png')" alt="" />
        </slot>
      </div>
    </div>
    <div class="decripe">
      <p class="font">{{ decripe }}</p>
    </div>
    <!-- 按钮 -->
    <div class="btn">
        <div class="btnBox">
            <slot name="btn"></slot>
        </div>
    </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "NewEmpty",
  data() {
    return {};
  },
  created() {},
  computed: {
    imgStyle() {
      let w = this.imageSize;
      return {
        width: w + "px",
        height: w + "px",
      };
    },
  },
  props: {
    //   使用何种图片
    image: {
      type: String,
    },

    // 组件的高度
    height:{
        type:String,
        default:'400'
    },

    // 图片的尺寸
    imageSize: {
      type: String,
      default:'200'
    },

    //   描述信息
    decripe: {
      type: String,
    },
  },
};
</script>
<style lang='scss' scoped>
@mixin setCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.NewEmpty{
    width: 100%;
   @include setCenter; 
}

.image{
     @include setCenter; 
    .imgBox{
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.decripe{
    font-size: 14px;
    color:#444;
    margin:15px 0;
    .font{
        text-align: center;
    }
}

.btn{
    @include setCenter; 
}
</style>