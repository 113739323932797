<template>
  <div class="right">
    <div class="lefttool">
      <!-- <div class="t1">
        <p>
          <i class="iconfont icon-hand" style="margin-right: 5px"></i
          >{{ $t("header.HeaderTopInfo.right.left.content") }}
        </p>
      </div> janlen@51tea.com -->
      <div class="t2"></div>
    </div>
    <div class="righttool">
      <div class="language">
        <div class="langKey">
          
          <span>{{ $t("Home.Button.LanguageSelect") }}</span>
        </div>
        <div class="langVal">
          <span
            class="langItem"
            :class="{ isActived: defaultLanguage === 'English' }"
            @click="ischoose('en')"
            >English</span
          >
          <span
            class="langItem"
            :class="{ isActived: defaultLanguage === '中文' }"
            @click="ischoose('zh')"
            >中文</span
          >
        </div>
      </div>

      <!-- <div
        class="perdetail"
        @mouseover="isvisible1 = true"
        @mouseout="isvisible1 = false"
      >
        <p class="userName">{{ defaultLanguage }}</p>
        <i class="iconfont icon-xiangxiajiantou"></i>
        <drop-down :isvisible="isvisible1" height="2px" position="center">
          <div class="loginMenu">
            <P @click="ischoose('en')">English</P>
            <P @click="ischoose('zh')">中文</P>
          </div>
        </drop-down>
      </div> -->

      <div
        class="perdetail"
        @mouseover="isvisible3 = true"
        @mouseout="isvisible3 = false"
      >
        <p class="userName">
          {{
            $store.state.user.token != ""
              ? $store.state.user.username
              : $t("Passport.PleaseSignin")
          }}
        </p>
        <i class="iconfont icon-xiangxiajiantou"></i>
        <drop-down :isvisible="isvisible3" height="2px" position="center">
          <div class="loginMenu">
            <P @click="login" v-if="$store.state.user.token == ''">{{
              $t("Passport.PleaseSignin")
            }}</P>
            <P @click="$router.push('/member/index')">{{
              $t("User.Account.Pages.MyAccount")
            }}</P>
            <P @click="loginOut" v-if="$store.state.user.token != ''">{{
              $t("Passport.Button.Logout")
            }}</P>
          </div>
        </drop-down>
      </div>
    </div>
  </div>
</template>
<script>
import { Pulldown, PullMenu, PullMenuItem } from "common/pulldown";
import { DropDownMenu, DropDown } from "common/dropDown";
import changeUserState from "@/common/addFun/disposeUserState";

export default {
  data() {
    return {
      PulldownState: ["USD", "INR"],
      //是否显示菜单
      isshowMenu: false,
      //当前选中的语言
      defaultLanguage: "English",

      //是否显示弹框
      isvisible1: false,
      isvisible2: false,
      isvisible3: false,
    };
  },
  props: {},
  components: {
    Pulldown,
    PullMenu,
    PullMenuItem,
    DropDownMenu,
    DropDown,
  },
  created() {
    this.changelang();
  },
  methods: {
    //退出登录
    loginOut() {
      changeUserState.remove();
      this.$store.dispatch("getCart");
    },

    //去登录
    login() {
      this.$createDialog("login", this);
    },

    //选择的语言
    ischoose(item) {
      // 如果选择的语言和当前存储的语言一致，则无需操作
      if (localStorage.getItem("lang") == item) {
        return;
      }
      this.$i18n.locale = item;
      localStorage.setItem("lang", item);
      this.isshowMenu = false;
      this.changelang();
      location.reload();
    },
    //显示下拉菜单
    showMenu(isshow) {
      this.isshowMenu = isshow;
    },
    //修改语言提示
    changelang() {
      switch (this.$i18n.locale) {
        case "en":
          this.defaultLanguage = "English";
          break;
        case "zh":
          this.defaultLanguage = "中文";
          break;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
//文字行高
$line-height: 20px;
.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lefttool {
    display: flex;
    font-size: 0.75rem;
    height: $line-height;
    color: var(--text-color-minor);
    justify-content: space-between;
    .t1 {
      padding-right: 14px;
      border-right: 1px solid var(--border-color-default);
      line-height: $line-height;
    }
    .t2 {
      padding-left: 14px;
      line-height: $line-height;
      .contact {
        color: var(--success-color);
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }

        // .mobileNum {
        //   color: var(--minor-color);
        //   margin-left: 5px;
        //   &:hover {
        //     border-bottom: 1px solid var(--text-color-minor)-dark;
        //     cursor: pointer;
        //   }
        // }
      }
    }
  }

  .pulldown {
    &:hover {
      color: var(--minor-color);
    }
  }

  .perdetail {
    position: relative;
    display: flex;
    max-width: 100px;
    align-items: center;
    cursor: pointer;
    .userName {
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分文字以...显示*/
    }

    .loginMenu {
      p {
        font-size: 0.75rem;
        // max-width: 8rem;
        width: 5rem;
        // min-width: 4rem;
        text-align: center;
        // cursor: pointer;
        &:hover {
          color: var(--minor-color);
        }
      }
    }

    .loginMenu p:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }

  .righttool {
    display: flex;
    margin-left: 14px;
    padding-left: 14px;
    // border-left: 1px solid var(--border-color-default);
    height: 100%;
    font-size: 0.75rem;
    color: var(--text-color-minor);
    i {
      font-size: 12px;
      margin: 0 4px;
    }

    .language {
      display: flex;
      align-items: center;
      .langKey {
        margin-right: 15px;
        line-height: $line-height;
        color:var(--text-color-default);
      }

      .langVal {
        margin-right: 10px;
        line-height: $line-height;
        .langItem {
          margin-right: 10px;
          cursor: pointer;
        }

        .isActived {
          color: var(--success-color);
        }
      }
    }
  }
}
</style>