var U = require("../utils/ajax.js");

var Add = function (opt) {
  return U.ajax({
    service: 'cart.add',
    data: {
      fromgoodslist: opt.data.fromgoodslist,
      goods_id: opt.data.goods_id,
      product_id: opt.data.product_id,
      type: opt.data.type ? opt.data.type : 'goods',
      num: opt.data.num ? opt.data.num : 1,
      is_fastbuy: opt.data.is_fastbuy ? opt.data.is_fastbuy : 0,
      e:opt.data.e
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};


var BatchAdd = function (opt) {
  return U.ajax({
    service: 'cart.batchAdd',
    data: {
      order_id: opt.data.order_id
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};

var Update = function (opt) {
  return U.ajax({
    service: 'cart.Update',
    data: {
      cart_ident: opt.data.cart_ident,
      selgoods: opt.data.selgoods,
      quantity: opt.data.quantity
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var dianCanAddCart = function (opt) {
  return U.ajax({
    service: 'cart.dianCanAddCart',
    data: {
      type: 'goods',
      goods_id: opt.data.goods_id,
      product_id: opt.data.product_id,
      cart_ident: opt.data.cart_ident,
      selgoods: opt.data.selgoods,
      quantity: opt.data.quantity,
      num: opt.data.quantity
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var Delete = function (opt) {
  return U.ajax({
    service: 'cart.delete',
    data: {
      cart_ident: opt.data.cart_ident,
      selgoods: opt.data.selgoods
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var cartEmpty = function (opt) {
  return U.ajax({
    service: 'cart.cartEmpty',
    data: {


    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};



//total结算页用
var Total = function (opt) {
  return U.ajax({
    service: 'cart.total',
    data: {
      shipping_id: opt.data.shipping_id ? opt.data.shipping_id : 0,
      payment: opt.data.payment ? opt.data.payment : 0,
      addr_id: opt.data.addr_id ? opt.data.addr_id : 0,
      selgoods: opt.data.selgoods,
      allgoods: localStorage.getItem('allgoods'),
      is_fastbuy: opt.data.is_fastbuy,
      extend: opt.data.extend,
      uc_id: opt.data.uc_id,//使用的优惠券id
      use_balance: opt.data.use_balance,//使用的余额抵扣
      use_point: opt.data.use_point,//使用的积分抵扣
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var Detail = function (opt) {
  return U.ajax({
    service: 'cart.detail',
    data: {
      selgoods: opt.data.selgoods,
      allgoods: opt.data.allgoods
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail ? opt.fail(res) : '';
    },
    context_this: opt.context_this
  });
};

var Checkout = function (opt) {
  return U.ajax({
    service: 'cart.checkout',
    data: {
      selgoods: opt.data.selgoods,
      is_fastbuy: opt.data.is_fastbuy,
      extend: opt.data.extend ? opt.data.extend : []
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail ? opt.fail(res, all) : '';
    },
    context_this: opt.context_this
  });
};

var Shipping = function (opt) {
  return U.ajax({
    service: 'cart.shipping',
    data: {
      addr_id: opt.data.addr_id,
      is_fastbuy: opt.data.is_fastbuy,
      selgoods: opt.data.selgoods,
      extend: opt.data.extend ? opt.data.extend : [],
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};

var Payment = function (opt) {
  return U.ajax({
    service: 'cart.payment',
    data: {},
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};

var UseDeposit = function (opt) {
  return U.ajax({
    service: 'cart-useDeposit',
    data: {
      use: opt.data.use
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var getCouponList = function (opt) {
  return U.ajax({
    service: 'cart.getCouponList',
    data: {
      'selgoods': opt.data.selgoods,
      'is_fastbuy': opt.data.is_fastbuy,
      'extend': opt.data.extend,
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var useCoupon = function (opt) {
  return U.ajax({
    service: 'cart.useCoupon',
    data: {
      code: opt.data.code,
      uc_id: opt.data.uc_id,
      selgoods: opt.data.selgoods,
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
var CheckShipDay = function (opt) {
  return U.ajax({
    service: 'cart.checkshipday',
    data: {
      date: opt.data.date,
      week: opt.data.week,
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    },
    context_this: opt.context_this
  });
};
module.exports = {
  Add: Add,
  Update: Update,
  dianCanAddCart: dianCanAddCart,
  cartEmpty: cartEmpty,
  Delete: Delete,
  Total: Total,
  Detail: Detail,
  Checkout: Checkout,
  Shipping: Shipping,
  Payment: Payment,
  UseDeposit222: UseDeposit,
  useCoupon: useCoupon,
  getCouponList: getCouponList,
  CheckShipDay,
  BatchAdd
};