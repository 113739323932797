<template>
  <div class="loader"></div>
</template>
<script>
export default {
    name: "LoadingTwo",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.loader {
  margin: 0 auto 2rem auto;
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: load-effect 1s infinite linear;
}

$color: #ed174a;
$size: 6px;
$x: 20px;
$beforex: -20px;
$y: 20px;

@keyframes load-effect {
  0% {
    box-shadow: $beforex $y 0 $size $color, 0 $y 0 0 $color,
      $x $y 0 $size $color;
  }

  25% {
    box-shadow: $beforex $y 0 0 $color, 0 $y 0 $size $color, $x $y 0 0 $color;
  }

  50% {
    box-shadow: $beforex $y 0 $size $color, 0 $y 0 0 $color,
      $x $y 0 $size $color;
  }

  75% {
    box-shadow: $beforex $y 0 0 $color, 0 $y 0 $size $color, $x $y 0 0 $color;
  }

  100% {
    box-shadow: $beforex $y 0 $size $color, 0 $y 0 0 $color,
      $x $y 0 $size $color;
  }
}
</style>