import Confirm from './Confirm'
export default {
    install: function (Vue) {
        const myConfirm = Vue.extend(Confirm)

        Vue.prototype.$confirm = function (type, title, cont,isAsyn,loadingType,context_this) {
            return new Promise((resolve, reject) => {
                let ele = new myConfirm({
                    el: document.createElement('div'),
                    data() {
                        return {
                            type,
                            title,
                            cont,
                            context_this
                        }
                    },
                    methods: {
                        confirm() {
                            if(isAsyn){
                                if(loadingType){
                                    ele.loadingType = loadingType
                                }
                                ele.btnIsShow=false
                                resolve(ele)
                            }else{
                                ele.close()
                                resolve()
                            }
                        },
                        cancel() {
                            ele.close()
                            reject()
                    
                        }
                    },
                })

                document.body.appendChild(ele.$el)
            })

        }

    }

}