<template>
  <div class="productDetails">
    <div class="container main">
      <div class="product" v-if="goodDetilData.goods_id">
        <div class="header_box">
          <product-header :goodDetilData="goodDetilData" ref="product_header"></product-header>
        </div>
        <div class="content_box">
          <product-content
            :goodDetilData="goodDetilData"
            :specArr="specArr"
            :specSku="specSku"
          ></product-content>
        </div>
      </div>

      <new-empty
        v-if="state == 'err'"
        image="defErr"
        :decripe="$t('Product.Msg.Err')"
      ></new-empty>

      <def-loading :isshow="isShowLoading"></def-loading>
    </div>
  </div>
</template>
<script>
import {
  ProductHeader,
  ProductContent,
} from "@/views/product/child/productDetailsChild";
import NewEmpty from "@/components/common/empty/NewEmpty";
import DefLoading from "@/components/common/loading/def/DefLoading";
export default {
  data() {
    return {};
  },

  computed: {
    state() {
      // 网络请求完毕，但没有数据，则判定请求失败
      if (!this.isShowLoading && this.goodDetilData.isBegin) {
        return "err";
      }
    },
  },
  props: {
    goodDetilData: {
      type: Object,
    },
    isShowLoading: Boolean,

    specArr: {
      type: Array,
      default:()=>[]
    },

    specSku: {
      type: Array,
      default:()=>[]
    },
  },
  created() {},
  components: {
    ProductHeader,
    ProductContent,
    NewEmpty,
    DefLoading,
  },
};
</script>
<style lang='scss' scoped>
.productDetails {
  .container {
    min-height: 400px;
    position: relative;
    .product {
      background-color: white;
      padding: 2.5rem;
      .header_box {
        margin-bottom: 1rem;
      }
    }

    .showEmpty {
      background-color: white;
    }
  }
}
</style>