<template>
  <div class="left">
    <div class="logo" @click="$router.push('/')">
      <img v-lazy="require('assets/img/logo/logo.png')" alt="" />
      <p class="decripe"></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.left {
  .logo {
    width: 272px;
    display: flex;
    img {
      height: 110px;
      width: auto;
    }

    .decripe {
      margin-top: 0.3rem;
      color: var(--text-color-minor);
      font-size: 0.75rem;
    }
  }
}
</style>