<template>
  <div class="nav">
    <div class="container main">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="item.url"
        @click="go(item.url)"
      >
        <div class="icon">
          <img :src="item.img" alt="" />
        </div>
        <div class="name" :class="{ active: index == 0 }">
          <span>{{$cl('text',item,$i18n.locale)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  watch: {},

  computed: {
    list() {
      let list = this.$store.state.common_data.header_menu.data;
      return list;
    },
  },

  methods: {
    go(url) {
      // 当前活跃的url
      let activeUrl = this.$route.path + "";

      // 判断当前需要跳转的url是否为gallerylist的
      let needGo_is_gallerylist = /gallerylist/gi.test(url);
      //判断当前活跃的路由是否为为gallerylist的
      let active_is_gallerylist = /gallerylist/gi.test(activeUrl);

      if (needGo_is_gallerylist && active_is_gallerylist) {
        this.$router.replace({
          path: url,
        });
        //  获取当前的列表组件
        let com = this.$root.$children[0].$refs.activeCom;
        com.init();
        com.$refs.galleryMain.$refs.galleryMainRight.currentPage = 1;
        return
      } else {
        this.$router.replace({
          path: url,
        });
      }

    },
  },

  props: {},
  components: {},
};
</script>
<style lang='scss' scoped>

.nav {
  border-bottom: 1px solid var(--border-color-default);
  margin-top: 30px;
  padding-bottom: 20px;
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.item {
  cursor: pointer;
  &:hover {
    .icon {
      img{
        transform:translateZ(0) scale(1.2);
      }
    }
  }
  .icon {
 
    width: 80px;
    height: 80px;
    // overflow: hidden;
    img {
         transition: all 0.3s;
      width: 100%;
      height: 100%;
      
    }
  }

  .name {
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px;
    text-align: center;
    color: var(--text-color-minor);
    font-size: var(--font-size-base);
  }

  // .active{
  //   background-color: rgb(196, 226, 196);
  //   color: forestgreen;

  // }
}
</style>