// 拦截加入购物车请求，在此过程中做一些操作


// 登录状态加入购物车数据的同时写入本地selgoods
function addSelgoods(opt, res) {
  let self_cart_ident =
    "goods_" +
    opt.data.goods_id +
    "_" +
    opt.data.product_id;

  let cartList = res.carts;

  let selgoods

  try {
    selgoods = JSON.parse(localStorage.getItem('selgoods'))
    if (!my_utils.isArray(selgoods)) {
      throw 'selgoods is not Array!'
    }
  } catch (err) {
    console.error(new Error(err));
    // 出现异常则重置
    selgoods = []
    localStorage.setItem('selgoods', JSON.stringify([]))
  }


  // 查看本地数据内部有无此添加的商品
  let has = selgoods.find((item) => {
    return item.cart_ident == self_cart_ident;
  });

  if (has) {
    // 如果有则从返回的购物车数据中将其数量更新
    has.quantity = cartList.find((item) => {
      return item.cart_ident == has.cart_ident;
    }).quantity;
  } else {
    let quantity = cartList.find((item) => {
      return item.cart_ident == self_cart_ident;
    }).quantity;

    selgoods.push({
      cart_ident: self_cart_ident,
      quantity,
    });
  }


  localStorage.setItem("selgoods", JSON.stringify(selgoods));

}


// 非登录状态下写入selgoods
function addSelgoodsInNologin(opt){
  let data = opt.data
  let cart_ident =
    "goods_" +
    data.goods_id +
    "_" +
    data.product_id;
  let quantity = data.num


  // 判断本地有无
  let selgoods

  try {
    selgoods = JSON.parse(localStorage.getItem('selgoods'))
    if (!my_utils.isArray(selgoods)) {
      throw 'selgoods is not Array!'
    }
  } catch (err) {
    console.error(new Error(err));
    selgoods = []
    // 出现异常则重置
    localStorage.setItem('selgoods', JSON.stringify([]))
  }


  // 原数据有无此项
  let res = selgoods.find((item) => {
    return item.cart_ident == cart_ident
  })
  if (res) {
    res.quantity += quantity
  } else {
    selgoods.push({
      cart_ident,
      quantity
    })
  }

  localStorage.setItem("selgoods", JSON.stringify(selgoods));
}


// 非登录状态则写入allgoods
function addAllgoods(opt) {
  let data = opt.data
  let cart_ident =
    "goods_" +
    data.goods_id +
    "_" +
    data.product_id;
  let quantity = data.num


  // 判断本地有无allgoods
  let allgoods

  try {
    allgoods = JSON.parse(localStorage.getItem('allgoods'))
    if (!my_utils.isArray(allgoods)) {
      throw 'allgoods is not Array!'
    }
  } catch (err) {
    console.error(new Error(err));
    allgoods = []
    // 出现异常则重置allgoods
    localStorage.setItem('allgoods', JSON.stringify([]))
  }


  // 原数据有无此项
  let res = allgoods.find((item) => {
    return item.cart_ident == cart_ident
  })
  if (res) {
    res.quantity += quantity
  } else {
    allgoods.push({
      cart_ident,
      quantity
    })
  }


  localStorage.setItem("allgoods", JSON.stringify(allgoods));

  let msg
  if (opt.lang == 'zh') {
    msg = '加入购物车成功'
  } else if (opt.lang == 'en') {
    msg = 'Add to cart successful'
  }

  // $my_vue.$message('success', msg)


  // 请求购物车数据
  $my_vue.$store
    .dispatch("getCart")
    .then((res) => {
        $my_vue.$myPopover(opt.data.e)
    })
    .catch((err) => { });
}


let method = {
  success: addSelgoods
}

let $cart_add = function (opt) {
  if (opt.data?.service != 'cart.add') return true

  // 判断用户当前是否已登录
  let isRegister = !!$my_vue.$store.state.user.token

  if (isRegister) {
    return method
  } else {
    addAllgoods(opt)
    addSelgoodsInNologin(opt)
    return false
  }

}


export default $cart_add