// 网络请求触发未登录处理
import changeUserState from '../disposeUserState'
function main(){

        //清楚浏览器存储的token和vuex中存储的
        changeUserState.remove()
        
        // openPop(['/home'])
        openPop()

}

// 触发弹框,rules里的路由不触发弹框
 function openPop(rules){
     // 判断当前浏览器是否有了一个弹框
     let istk = !!document.querySelector('.dialog_useLoginOrGegister')
     if (istk) {
       return
     }
    //  //判断当前路由是否为规则里的
    //  let path = window.location.pathname
    //  rules = rules.map((item)=>{
    //      item =('^'+'\\'+item)
    //      return item
    //  })
    //  var reg = new RegExp(rules.join('|'));  
    //  if (!reg.test(path)) {
    //    $my_vue.$createDialog('login')
    //  }
    
    let isLogin = $my_vue.$route.meta.needLogin
    if(isLogin){
      $my_vue.$createDialog('login')
    }
    

 }

export default main



 
