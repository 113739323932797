<template>
 <!-- <transition name="fade"> -->
  <div class="dropdownBox" ref="dropdown" v-show="isvisible">
    <div class="hidden" :style="{height:height}"></div>
    <div class="dropdown" :style="dropDownStyle">
      <slot></slot>
    </div>
  </div>
   <!-- </transition> -->
</template>
<script>
export default {
  name:'DropDown',
  data() {
    return {
      // 父元素的高度
      parentHeight: 0,
      //父元素的宽度
      parentWidth: 0,
    };
  },
  created() {},
  props: {
    //方向
    position: {
      type: String,
      default: "left",
    },

    // 下拉框的样式
    dropDownStyle:{
      type:Object
    },

    //透明高度
    height:{
      type:String,
      default:'8px'
    },

    //是否显示
    isvisible: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let obj = this.$refs.dropdown;
      this.parentHeight = obj.parentElement.offsetHeight;
      this.parentWidth = obj.parentElement.offsetWidth;

      obj.style.top = this.parentHeight + "px";

      if (this.position == "left") {
        obj.style.right = "0px";
      }
      else if(this.position == "right"){
        obj.style.left = "0px";
      }else if(this.position == "center"){
         obj.style.left = "50%"
         obj.style.transform='translateX(-50%)';
      }
    },
  },
};
</script>
<style lang='scss' scoped>


.dropdownBox {
  position: absolute;

  z-index: 70;
  .hidden{
    opacity: 0;
    width: 100%;
   
  }
  .dropdown {
    // background-clip:content-box;
    background-color: white;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
}


.fade-enter-active, .fade-leave-active {
  transition:opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
opacity: 0;
}
</style>