<template>
  <div class="headertop">
    <div class="container">
      <header-top-left></header-top-left>
      <header-top-right></header-top-right>
    </div>
  </div>
</template>
<script>
import { HeaderTopLeft, HeaderTopRight } from "./HeaderTopChild";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    HeaderTopLeft,
    HeaderTopRight,
  },
  props: {},
};
</script>
<style lang='scss' scoped>

.headertop {
  background-color: white;
  border-bottom: 1px solid var(--border-color-default);
  .container {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
}
</style>