
// 登录成功之后清楚allgoods
function clear(opt,res,all){
    localStorage.removeItem('allgoods')
}


// 登录之前将本地的allgoods传入
function beforeLogin(opt){
    let allgoods
    try {
        allgoods = JSON.parse(localStorage.getItem('allgoods'))
        if (!my_utils.isArray(allgoods)) {
            throw 'allgoods is not Array!'
        }
    } catch (err) {
        console.error(new Error(err));
        // 出现异常则重置allgoods
        allgoods = []
        localStorage.setItem('allgoods', JSON.stringify(allgoods))
    }

    opt.data.allgoods = JSON.stringify(allgoods)

}

let method = {
    success:clear
}

let $login_add = function (opt) {
    if (opt.data?.service != 'user.login') return true
    beforeLogin(opt)
    return method
  
  }

export default $login_add