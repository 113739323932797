<template>
  <div class="loadingBox" v-if="isshow" :style="setStyle">
    <loading></loading>
  </div>
</template>
<script>
import Loading from "../type/Loading";
export default {
  name: "PageLoading",
  data() {
    return {
        isshow:true
    };
  },
  methods:{
      close(){
          this.isshow = false
           setTimeout(()=>{
        this.$destroy()
      },100)
      }
  },
  computed:{
    setStyle(){
      if(!this.isFullScreen){
        return {
            position:'absolute'
        }
      }else{
        return {
          position:'fixed'
        }
      }
    }

  },
  created() {},
  components: {
    Loading,
  },
};
</script>
<style lang='scss' scoped>
.loadingBox{
    top:0;
    bottom:0;
    left:0;
    right: 0;
    z-index: 10;
    background-color: rgba(255,255,255,.9);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
