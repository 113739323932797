import '@/common/check/checkPc.js'
import my_utils from '@/common/my_utils'
import '@/common/addFun/networkIntercept'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './common/lang'

import '@/directive'

import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

import { Input,DatePicker,Radio,RadioGroup,Cascader,Form,FormItem,Rate,Tabs,TabPane} from 'element-ui';
import VueLazyload from 'vue-lazyload'
import Dialog from 'common/dialog'
import Confirm from 'common/messageBox'
import 'common/message'
import 'common/loading'
import 'common/backTop'
import 'content/popover/index.js'

Vue.config.productionTip = false
Vue.use(Input)
Vue.use(DatePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cascader)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Rate)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Dialog)
Vue.use(Confirm)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('assets/img/imgState/error.png'),
  loading:  require('assets/img/imgState/loading.png'),
  attempt: 1
})





const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')







