function isType(type){
    return function(obj){
        return Object.prototype.toString.call(obj) === `[object ${type}]`
    }
}

// 添加一个输入时间戳转为时间方法
function changeTimestamp(timestamp) {
    return new Date(parseInt(timestamp) * 1000).toLocaleString().split(' ')[0];
 }
 

let isArray = isType('Array')


globalThis.my_utils ={
    isArray,
    changeTimestamp
}