<template>
  <div class="FindPassword">
    <div class="content">
      <div class="findMethod">
        <div class="title">
          * {{ $my_vue.$t("Passport.Pages.FindByType") }}
        </div>
        <ul class="choose">
          <li :class="{ actived: choose == 0 }" @click="changeChoose(0)">
            {{ $my_vue.$t("Passport.Email") }}
          </li>
          <li :class="{ actived: choose == 1 }" @click="changeChoose(1)">
            {{ $my_vue.$t("Passport.Mobile") }}
          </li>
        </ul>
      </div>

      <div class="emailOrMobile">
        <div class="title">* {{ emailOrMobile.name }}</div>
        <div class="input">
          <has-icon-input
            :type="emailOrMobile.type"
            v-model="emailAndMobile"
            :height="40"
          ></has-icon-input>
        </div>
      </div>

      <div class="verification">
        <div class="title">
          * {{ $my_vue.$t("Passport.Pages.FindPwdCaptcha") }}
        </div>
        <div class="verMain">
          <div class="enter">
            <has-icon-input
              type="text"
              v-model="verification"
              :height="40"
            ></has-icon-input>
          </div>
          <timer
            :time.sync="time"
            :deadline="deadline"
            @stop="stopTimer"
            v-if="isOpenTime"
          ></timer>
          <div
            class="sendBtn"
            :class="{ disabled: !IscaptchaBtn }"
            @click="checkVer"
            v-if="!isOpenTime"
          >
            {{ $my_vue.$t("Passport.Pages.SendVerifyToEmail") }}
          </div>
          <div class="sendBtn disabled" v-else>{{ residueTime }}</div>
        </div>
      </div>

      <div class="newPassword">
        <div class="title">
          * {{ $my_vue.$t("Passport.Pages.FindPwdNewPassword") }}
        </div>
        <div class="input">
          <has-icon-input
            type="password"
            v-model="newpassword"
            :height="40"
          ></has-icon-input>
        </div>
      </div>

      <div class="send">
        <div class="btn" :class="{ disabled: !IsSendBtn }" @click="checkAll">
          <span>{{ $my_vue.$t("Goods.Pages.OK") }}</span>
        </div>
      </div>

      <div class="bottomHit">
        <p class="lostpassword" @click="changeType('login')">
          {{ $my_vue.$t("Passport.PleaseSignin") }}
        </p>
        <p class="lostpassword" @click="changeType('register')">
          {{ $my_vue.$t("Passport.CreateAccount") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import HasIconInput from "common/input/HasIconInput";
import Timer from "common/time/Timer";
import { Passport, Resetpwd } from "@/model/User.js";
export default {
  name: "FindPassword",
  data() {
    return {
      reg: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
      timeDuration: 120,
      choose: 0,
      typeArr: ["email", "mobile"],
      emailAndMobile: "",
      verification: "",
      newpassword: "",
      time: {},
      deadline: "",
      isOpenTime: false,

      //   验证码按钮是否可以点击
      IscaptchaBtn: true,

      // 提交按钮是否可以点击
      IsSendBtn: true,
    };
  },
  created() {},
  computed: {
    emailOrMobile() {
      if (this.choose == 0) {
        return {
          name: $my_vue.$t("Passport.Email"),
          type: "email",
        };
      } else if (this.choose == 1) {
        return {
          name: $my_vue.$t("Passport.Mobile"),
          type: "text",
        };
      }
    },

    $my_vue(){
      return $my_vue
    },

    // 剩余时间
    residueTime() {
      return parseInt(this.time.m * 60) + parseInt(this.time.s) + "s";
    },
  },
  methods: {
    //切换类别
    changeType(type) {
      this.$emit("update:type", type);
    },
    changeChoose(index) {
      if (index == this.choose) {
        return;
      }
      this.choose = index;
      this.emailAndMobile = "";
    },

    //发送验证码之前的操作
    checkVer() {
      if (!this.IscaptchaBtn) {
        return;
      }
      this.IscaptchaBtn = false;
      //邮箱验证正则
      if (this.choose == 0) {
        if (!this.reg.test(this.emailAndMobile)) {
          this.IscaptchaBtn = true;
          return $my_vue.$message(
            "error",
            $my_vue.$t("Passport.Validation.EmailError")
          );
        }
      }

      //检查是否有空和空格
      if (this.emailAndMobile == "" || /\s/g.test(this.emailAndMobile)) {
        this.IscaptchaBtn = true;
        return $my_vue.$message(
          "error",
          $my_vue.$t("Common.Msg.InputError")
        );
      }

      this.sendVer();
    },

    //发送找回密码之前的操作
    checkAll() {
      if (!this.IsSendBtn) {
        return;
      }

      this.IsSendBtn = false;
      if (this.choose == 0) {
        if (!this.reg.test(this.emailAndMobile)) {
          this.IsSendBtn = true;
          return $my_vue.$message(
            "error",
            $my_vue.$t("Passport.Validation.EmailError")
          );
        }
      }

      if (this.verification != "" && this.newpassword != "") {
        this.changePassword();
      } else {
        this.IsSendBtn = true;
        return $my_vue.$message(
          "error",
          $my_vue.$t("Passport.Validation.isEmpty")
        );
      }
    },

    //设置倒计时
    setTime() {
      this.deadline = Date.now() + this.timeDuration * 1000;
      this.isOpenTime = true;
    },

    //时间到了停止倒计时
    stopTimer() {
      this.isOpenTime = false;
      this.deadline = "";
      this.time = {};
    },

    //发送找回密码请求
    changePassword() {
      Passport.Resetpwd({
        data: {
          type: this.typeArr[this.choose],
          captcha: this.verification,
          newpassword: this.newpassword,
          email: this.emailAndMobile,
          mobile: this.emailAndMobile,
        },
        succ: (res, all) => {
          this.IsSendBtn = true;
          $my_vue.$message("success", all.msg);
          this.$emit("update:isshow", false);
        },
        fail: (res, all) => {
          this.IsSendBtn = true;
          $my_vue.$message("error", all.msg);
        }
      });
    },

    // 发送验证码
    sendVer() {
      Passport.SendVer({
        data: {
          email: this.emailAndMobile,
          event: "resetpwd",
        },
        succ: (res, all) => {
          this.IscaptchaBtn = true;
          this.setTime();
          $my_vue.$message("success", all.msg);
        },
        fail: (res, all) => {
          this.IscaptchaBtn = true;
          this.emailAndMobile = "";
          $my_vue.$message("error", all.msg);
        }
      });
    },
  },
  components: {
    HasIconInput,
    Timer,
  },
};
</script>
<style lang='scss' scoped>
.FindPassword {
  margin-top: 1.25rem;
  width: 35vw;
  .content {
    .title {
      font-size: 0.75rem;
      color:var(--text-color-minor);
      margin-bottom: 0.75rem;
    }
    .findMethod {
      .choose {
        display: flex;
        li {
          margin-right: 0.75rem;
          padding: 0.2rem 1rem;
          background-color: #fff1ee;

          color: var(--text-color-default);
          height: 1.2rem;
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          //   border-radius: 0.2rem;
          list-style: none;
          cursor: pointer;
          &:hover {
            background-color:var(--main-color);
            color: white;
          }
        }

        .actived {
          background-color:var(--main-color);
          color: white;
        }
      }
    }

    .emailOrMobile {
      margin-top: 1.25rem;
      margin-bottom: 0.75rem;
    }

    .verification {
      .verMain {
        display: flex;
        align-items: center;
        .enter {
          width: 30%;
          //   margin-right: 0.5rem;
        }
        .sendBtn {
          height: 40px;
          padding: 0 1rem;
          background-color:var(--main-color);
          color: white;
          font-size: 0.75rem;
          text-align: center;
          cursor: pointer;
          line-height: 40px;
          &:hover {
            opacity: 0.8;
          }
        }

        .disabled {
          text-align: center;
          background-color: #5668b1;
          cursor: not-allowed;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .newPassword {
      margin-top: 0.75rem;
    }

    .send {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      //  justify-content: flex-end;
      .btn {
        height: 30px;
        padding: 0 1.5rem;
        color: white;
        line-height: 30px;
        font-size: 0.75rem;
        cursor: pointer;
        background-color:var(--main-color);
        &:hover {
          opacity: 0.8;
        }
      }

      .disabled {
        text-align: center;
        background-color: #5668b1;
        cursor: not-allowed;
        &:hover {
          opacity: 1;
        }
      }
    }

    .bottomHit {
      display: flex;

      .lostpassword {
        margin-right: 0.4rem;
        font-size: 0.75rem;
        color:var(--minor-color);
        cursor: pointer;
      }
    }
  }
}
</style>