<template>
  <div class="pullmenu">
      <slot></slot>
  </div>
</template>
<script>
export default {
   name:'Pullmenu',
  data() {
    return {};
  },
  props:{
      //
  },
  created() {},
};
</script>
<style lang='scss' scoped>

</style>