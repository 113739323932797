<template>
  <div class="main">
    <div class="container">
      <header-main-left></header-main-left>
      <header-main-center></header-main-center>
      <header-main-right></header-main-right>
    </div>
  </div>
</template>
<script>
import {
  HeaderMainCenter,
  HeaderMainRight,
  HeaderMainLeft,
} from "./HeaderMainChild";
export default {
  data() {
    return {};
  },
  components: {
    HeaderMainCenter,
    HeaderMainRight,
    HeaderMainLeft,
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.main{
    .container{
            display: flex;
    justify-content: space-between;
    align-items: center;


    padding-top:20px;
    padding-bottom:10px;

    }
}
</style>