<template>
  <div
    class="Popover"
    ref="Popover"
    :class="[className]"
    v-if="isShow"
    v-clickOutside="close"
    :style="popoverStyle"
  >
    <div class="mainBox">
      <div class="message">
        <!-- <span>加入购物车成功</span> -->
        <span>{{ temApp.$t("Cart.Msg.AddCartSucc") }}</span>
      </div>
      <div class="cartDetail">
        <p
          v-html="
            temApp.$t('Cart.Msg.AddCartDetail', {
              goods_num_kind: cartInfo.goods_num_kind,
              goods_num_quant: cartInfo.goods_num_quant,
              total_price_text: cartInfo.total_price_text,
            })
          "
        ></p>
      </div>
      <div class="opeBtn">
        <div class="enterCart btnItem" @click="enterCart">
          <span>{{ temApp.$t("Cart.EnterCart") }}</span>
        </div>
        <div class="buyAgain btnItem" @click="close">
          <span>{{ temApp.$t("Cart.BuyAgain") }}</span>
        </div>
      </div>
    </div>

    <div class="closeBtn" @click="close">
      <i class="icon iconfont icon-guanbi"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "Popover",
  data() {
    return {
      isShow: true,
      temApp: $my_vue,
    };
  },
  created() {
    this.init();
  },
  computed: {
    popoverStyle() {
      return {
        top: this.clickDetail.pageY + "px",
        left: this.clickDetail.pageX + "px",
      };
    },

    cartInfo() {
      return $my_vue.$store.state.cart.cart.total;
    },
  },
  methods: {
    init() {},

    close() {
      this.isShow = false;
      setTimeout(() => {
        this.$destroy();
      }, 10);
    },

    enterCart() {
      this.close();
      // 去除购买弹框
      $my_vue.$store.commit("updateProductTk", {
        val: "",
        isOpen: false,
      });
      $my_vue.$router.push({
        path: "/cart/index",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.Popover {
  width: 300px;
  min-height: 140px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-radius: 4px;
  border: 4px solid #e5e5e5;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  box-sizing: border-box;
  .mainBox {
    .message {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: var(--main-color);
    }

    .cartDetail {
      margin: 15px 0;
      p {
        text-align: center;
        font-size: 12px;
        color: var(--text-color-minor);
        & /deep/ {
          span {
            color: var(--main-color);
            font-weight: 600;
            margin: 0 2px;
          }
        }
      }
    }

    .opeBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      .enterCart {
        margin-right: 5px;
      }
      .btnItem {
        cursor: pointer;
        border-radius: 3px;
        width: 100px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        background-color: var(--main-color);
        color: white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    .icon {
      line-height: 20px;
      font-size: 14px;
      color: var(--text-color-minor);
      cursor: pointer;
    }
  }
}
</style>