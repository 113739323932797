<template>
  <div class="productHeader">
    <h1 class="title">
      {{ getContent($cl("name", goodDetilData_tem, $i18n.locale)) }}
    </h1>
    <div class="product-meta">
      <div class="product-rating">
        <div class="star">
          <Star :starNum="5"></Star>
        </div>
        <div class="starLink">
          <a href="#"></a>
        </div>
      </div>
      <!-- <div class="sku-wrapper">
        <span>SKU:</span> <span class="sku">{{ goodDetilData.bn }}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
import Star from "common/star/star";
export default {
  name: "ProductHeader",
  data() {
    return {
      goodDetilData_tem: this.goodDetilData,
    };
  },
  components: {
    Star,
  },
  props: {
    goodDetilData: {
      type: Object,
    },
  },
  created() {},
  methods: {
    // 去除（）内的内容
    getContent(target) {
      let reg = /\((.+?)\)/gi;
      // let match = target.match(reg)
 
        return target.replace(reg,'')

    },
  },
};
</script>
<style lang='scss' scoped>
.productHeader {
  .title {
    font-size: 1.5rem;
    color: var(--text-color-title);
    font-weight: 600;
  }

  .product-meta {
    display: flex;
    .product-rating {
      display: flex;

      align-items: center;
      .starLink {
        a {
          font-size: 0.75rem;
          text-decoration: none;
          color: var(--text-color-minor);
        }
      }
    }

    .sku-wrapper {
      margin-left: 0.9375rem;
      span {
        font-size: 0.75rem;
        color: var(--text-color-minor);
      }
      .sku {
        color: var(--text-color-title);
      }

      &::before {
        content: "|";
        font-size: 0.75rem;
        color: var(--border-color-default);
        margin-right: 0.9375rem;
      }
    }
  }
}
</style>