

// 未登录的情况下加商品
function update(opt) {

    let allgoods
    // 查看本地有无该商品
    try {
        allgoods = JSON.parse(localStorage.getItem('allgoods'))
        if (!my_utils.isArray(allgoods)) {
            throw 'allgoods is not Array!'
        }
    } catch (err) {
        console.error(new Error(err));
        // 出现异常则重置allgoods
        allgoods = []
        localStorage.setItem('allgoods', JSON.stringify(allgoods))
    }

    let cart_ident = opt.data.cart_ident
    let quantity = opt.data.quantity

    let has = allgoods.find((item) => {
        return item.cart_ident == cart_ident
    })

    if (has) {
        has.quantity = quantity
    }

    localStorage.setItem('allgoods', JSON.stringify(allgoods))

    // 请求购物车数据
    $my_vue.$store
        .dispatch("getCart")
        .then((res) => {
            opt.succ(res)
        })
        .catch((err) => {
            opt.fail(err,err)
        });

}

let method = {

}

let $cart_Update = function (opt) {
    if (opt.data?.service != 'cart.Update') return true
    // 判断用户当前是否已登录
    let isRegister = !!$my_vue.$store.state.user.token

    if (isRegister) {
        return method
    } else {
        update(opt)
        return false
    }

}

export default $cart_Update