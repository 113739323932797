// 个人中心路由权限
function account_view(to,from,next){

    //如果此用户已经登录，则重定向到会员中心
    if(to.path.startsWith('/account')){
        let isLogin = localStorage.getItem('token')
        if(isLogin){
            next('/member/index')
        }
    }

}


export { account_view}