<template>
  <pop-up :isshow="isshow" borderRadius="0.875rem">
    <div class="content_box">
      <product-details
        :goodDetilData="goodDetilData"
        :isShowLoading="isShowLoading"
        :specArr="specArr"
        :specSku="specSku"
      ></product-details>
      <div class="closeBtn" @click="close">
        <i class="iconfont icon-guanbi"></i>
      </div>
    </div>
  </pop-up>
</template>
<script>
import PopUp from "common/popup/PopUp";
import ProductDetails from "@/views/product/child/ProductDetails";
import Goods from "@/model/Goods";
export default {
  data() {
    return {
      // 是否打开商品详情框
      isshow: true,
      goodDetilData: {
        isBegin: true,
      },
      isShowLoading: true,
      specArr: [],
      specSku: [],
    };
  },
  created() {
    this.goodDetil();
  },
  methods: {
    //获取商品详情
    goodDetil() {
      this.isNetwork = true;
      Goods.Goods.Detail({
        data: {
          service: "goods.detail",
          product_id: this.product_id,
        },
        succ: (res, all) => {
          this.isShowLoading = false;
          this.goodDetilData = res.goods;
          res.specArr && (this.specArr = res.specArr);
          res.specSku && (this.specSku = res.specSku);
        },
        fail: (res, all) => {
          this.isShowLoading = false;
          this.$message("error", all.msg);
        },
      });
    },

    close() {
      this.$store.commit("updateProductTk", {
        val: "",
        isOpen: false,
      });
    },
  },
  components: {
    PopUp,
    ProductDetails,
  },
  props: {
    product_id: [String, Number],
  },
};
</script>
<style lang='scss' scoped>
.content_box {
  width: 50vw;
  min-height: 40vh;
  box-sizing: border-box;
  padding: 2.5rem;
  position: relative;
  max-height: 75vh;
  overflow: auto;
  .closeBtn {
    right: 1rem;
    top: 1rem;
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: #c2c2d3;
    border-radius: 50%;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    line-height: 2rem;
    text-align: center;
  }
}
</style>