<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.swiper-slide {
  height: auto;
  img {
    width: 100%;
    
  }
}
</style>