import Vue from 'vue'
import Message from './Message'

// 实现提示的单例


Vue.use({
    install: function (Vue) {
        const myMessage = Vue.extend(Message)
        // 弹框的类名
        let className = 'my_message'
        Vue.prototype.$message = function (type, message, context_this,time) {
            // 实现单例，判断当前页面有无此弹框，有则隐藏之前的弹框
            let comArr = [...document.body.querySelectorAll(`.${className}`)]
            if(!!comArr.length){
                comArr.forEach((item)=>{
                    item.style.opacity = 0
                })
            }

            let ele = new myMessage({
                el: document.createElement('div'),
                data() {
                    return {
                        type,
                        message,
                        context_this,
                        name:className
                    }
                }
            })
            document.body.appendChild(ele.$el)
            ele.$el.$component = ele
            let _t = 1000
            if(time){
                _t = time
            }
            setTimeout(() => {
                ele.close()
            }, _t)
        }
    }

})