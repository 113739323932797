import PageLoading from './PageLoading'
export default {
    install: function (Vue) {
        const myPageLoading = Vue.extend(PageLoading)
        Vue.prototype.$loading = function (target,isFullScreen) {
            let ele = new myPageLoading({
                el: document.createElement('div'),
                data() {
                    return {
                        isFullScreen
                    }
                }
            })

            target.appendChild(ele.$el)

            return ele
        }
    }

}