<template>
  <div class="info" :class="[type]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    type: {
      type: String,
      default: "fefault",
    },
  },
};
</script>
<style lang='scss' scoped>


.info{
  width: 100%;
}

.warn {
  background-color: #ffeef2;
  color: #be143c;
  text-align: center;
  font-size: 0.75rem;
  padding: 10px 10px;
  box-sizing: border-box;
}

</style>