<template>
<div class="left">
    <ul class="leftBox">
        <li><span @click="$router.push('/member/orders')">{{$t('User.Account.Pages.MyOrders')}}</span></li>
        <li><span @click="$router.push('/member/favorite')">{{$t('User.Account.Pages.Wishlist')}}</span></li>
        <!-- <li><span @click="$router.push('/article/list')">{{$t('User.Account.Pages.HelpCenter')}}</span></li>
        <li><span @click="$router.push('/member/balance')">{{$t('User.Account.Pages.MyBalance')}}</span></li> -->
   
    </ul>
</div>
</template>
<script>
export default {
data(){
return {
}
},
props:{

},
created(){
},
}
</script>
<style lang='scss' scoped>

.leftBox{
display: flex;
li{
    list-style: none;
    margin-right:15px;
    span{
        color:var(--text-color-minor);
        text-decoration: none;
        font-size:0.75rem;
        display: block;
        height: 39px;
        line-height: 39px;
        cursor: pointer;
        &:hover{
            color:var(--minor-color);
        }
    }
}
}
</style>