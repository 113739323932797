<template>
  <transition name="slide-fade" appear>
    <div class="popup" v-if="isshow">
      <div class="content_com" :style="{borderRadius}">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
  created(){
  },
  methods: {},
  components: {},

  props: {
    isshow: {
      type: Boolean,
      default: true,
    },

     borderRadius:{
       type:String,
       default:''
     },

    width: {
      type: String,
      // default:'30vw'  :style="{width:width}"
    },
  },
};
</script>
<style lang='scss' scoped>

.popup {


  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: -100vw;
  top: -100vh;
  bottom: -100vh;
  right: -100vw;
  z-index: 95;
  .content_com {
    position: fixed;
    top: 50%;
    left: 50%;
   
    transform: translateX(-50%) translateY(-50%);
    z-index: 99;
    background-color: white;
    // padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }


}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {


  transform: scale(0.5, 0.5);
  opacity: 0;
}
</style>