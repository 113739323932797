const member =  {
    state: {
        //跳转到那个订单详情
        OrderState:''
    },

    mutations: {
        updateOrderState(state,payload){
            state.OrderState = payload
        }
   
    },

}

export default member

