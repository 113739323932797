<template>
  <div class="productContent">
    <div class="row">
      <div class="left col-6-md">
        <div class="product-images">
          <product-swiper :goodDetilData="goodDetilData"></product-swiper>
        </div>
      </div>
      <div class="right col-6-md">
        <div class="leftprice">
          <left-price
            :specArr="specArr"
            :specSku="specSku"
            :goodDetilData="goodDetilData"
            v-if="!!goodDetilData.goods_id"
          ></left-price>
        </div>
        <!-- <div class="leftwarn" v-if="!isHidden">
          <info type="warn">error</info>

          <div class="advantage">
            <div class="item">
              <div class="icon">
                <i class="iconfont icon-building"></i>
              </div>
              <span>Free Shipping apply to all orders over $100</span>
            </div>
            <div class="item">
              <div class="icon">
                <i class="iconfont icon-building"></i>
              </div>
              <span>Free Shipping apply to all orders over $100</span>
            </div>
            <div class="item">
              <div class="icon">
                <i class="iconfont icon-building"></i>
              </div>
              <span>Free Shipping apply to all orders over $100</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  ProductSwiper,
  LeftPrice,
} from "@/views/product/child/productDetailsChild/productContentChild";
import Info from "common/info/Info";
export default {
  data() {
    return {};
  },

  mounted() {},
  methods:{

  },
  components: {
    ProductSwiper,
    LeftPrice,
    Info,
  },
  props: {
    goodDetilData: {
      type: Object,
    },

    isHidden: {
      type: Boolean,
      default: false,
    },

    specArr: {
      type: Array,
      default: () => [],
    },

    specSku: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.row {
  .right {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    .leftprice {
      width: 100%;
    }

    .leftwarn {
      max-width: 16.25rem;
      flex: 0 0 16.25rem;
      // background-color:red;
      margin-left: 2.5rem;

      .advantage {
        background-color: var(--bg-color-hint);
        padding: 1.875rem;
        margin-top: 1rem;
        .item {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          color: var(--text-color-title);
          margin-bottom: 1.875rem;
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
          span {
            line-height: 1.25rem;
          }

          .icon {
            margin-right: 0.875rem;
            i {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>