<template>
  <transition name="slide-fade" appear>
    <div class="confirm" v-if="isShow">
      <div class="font">
        <div class="title" v-show="btnIsShow">{{ title }}</div>
        <div class="cont" v-show="btnIsShow">
          <i class="iconfont icon-jinggao danger" v-if="type == 'danger'"></i>
          {{ cont }}
        </div>
        <div class="loading" v-show="!btnIsShow">
          <loading v-if="loadingType == 'default'"></loading>
          <loading-two v-if="loadingType == 'two'"></loading-two>
          <!-- <div class="decripe">正在执行中...</div> -->
        </div>
        <div class="btn" v-show="btnIsShow">
          <div class="cancelBtn item" @click="cancel">{{context_this.$t('Common.Confirm.Cancel')}}</div>
          <div class="confirmBtn item" @click="confirm">{{context_this.$t('Common.Confirm.Yes')}}</div>
        </div>
      </div>

    </div>
  </transition>
</template>
<script>
import LoadingTwo from "common/loading/type/LoadingTwo";
import Loading from "common/loading/type/Loading";
export default {
  name: "Confirm",
  data() {
    return {
      isShow: true,
      btnIsShow: true,


      // 加载动画的类型
      loadingType: "two",
    };
  },

  methods: {
    close() {
      this.isShow = false;
      setTimeout(()=>{
        this.$destroy()
      },100)
    },
  },

  created() {},
  destroyed(){
  
  },
  components: {
    Loading,
    LoadingTwo,
  },
};
</script>
<style lang='scss' scoped>
.confirm {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: -100vw;
  top: -100vh;
  right: -100vw;
  bottom: -100vh;
  z-index: 95;
  .font {
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    position: fixed;
    z-index: 999;
    min-width: 1rem;
    min-height: 1rem;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .title {
      font-size: 1rem;
      color: #303133;
    }
    .cont {
      font-size: 0.875rem;
      margin: 1rem 0;
      color: #606266;
      display: flex;
      align-items: center;
      i {
        margin-right: 0.5rem;
      }
      .danger {
        font-size: 1.5rem;
        color: #e6a23c;
      }
    }

    .loading {
      padding: 2rem 3rem;
      .decripe {
        font-size: 0.75rem;
        margin-top: 0.75rem;
        color: #777;
        text-align: center;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      .item {
        padding: 0.25rem 0.875rem;
        font-size: 0.75rem;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }

      .cancelBtn {
        border: 1px solid #dcdfe6;
        background-color: white;
        margin-right: 0.5rem;
      }

      .confirmBtn {
        background-color: #d51243;
        color: white;
      }
    }
  }
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: scale(0.5, 0.5);
  opacity: 0;
}
</style>