<template>
  <div class="center">
    <!-- <div class="left">
      <div class="font">
        <p class="p1">{{ $t("header.HeaderMainInfo.center.left.top") }}</p>
        <p class="p2">{{ $t("header.HeaderMainInfo.center.left.bottom") }}</p>
      </div>
      <div class="icon">
        <i class="iconfont icon-xiangxiajiantou"></i>
      </div>
    </div>
    
         backgroundColor="#fff"
         rightBtnBgColor="#fff"
     -->
    <div class="right">
      <!-- <has-icon-input
        :height="60"
        type="text"
        radius="4px"
        :issetRighticonBtn="true"
        backgroundColor="#fff"
        rightBtnBgColor="#fff"
        v-model="inputData"
        @InputBtnClick="InputBtnClick"
        @keyup.enter.native = "InputBtnClick"
        :placeholder="$t('Goods.Pages.SearchProducts')"
      >
        <template v-slot:rightBtn>
          <i class="iconfont icon-seach headerinput"></i>
        </template>
      </has-icon-input> -->

      <div class="descripe" v-html="$t('Home.Msg.TopDecripe')">
        <!-- <p>Tea, originated in China, has a history of 5000 years </p>
        <p>The production of top-level tea is very rare. In the past, it was generally circulated in China</p>
         <p>Today, through this website, we sell top Chinese tea to 197 countries in the world and Antarctica</p>
         <br />
         <p>
         ... now, place your order and enjoy high-quality Chinese tea
         </p> -->
        <!-- <p>Tea, originated in China, has a history of 5000 years </p>
        <p>The production of top-level tea is very rare. In the past, it was generally circulated in China</p>
         <p>Today, through this website, we sell top Chinese tea to 197 countries in the world and Antarctica</p>
         <br />
         <p>
         ... now, place your order and enjoy high-quality Chinese tea
         </p> -->
      </div>
    </div>
  </div>
</template>
<script>
import HasIconInput from "common/input/HasIconInput";
import { Pulldown, PullMenu, PullMenuItem } from "common/pulldown";

export default {
  data() {
    return {
      //输入框中的内容
      inputData: "",
    };
  },
  created() {},
  methods: {
    //搜索按钮被点击
    InputBtnClick() {
      if (this.inputData == "") {
        return this.$message("error", "输入的内容为空！");
      } else {
        if (this.$route.path.startsWith("/gallerylist")) {
          this.$router.push({
            path: "/gallerylist/index/list",
            query: {
              keyword: this.inputData,
            },
          });
          setTimeout(() => {
            location.reload();
          }, 100);
        } else {
          this.$router.push({
            path: "/gallerylist/index/list",
            query: {
              keyword: this.inputData,
            },
          });
        }
      }
    },
  },
  components: { Pulldown, PullMenu, PullMenuItem, HasIconInput },
};
</script>
<style lang='scss' scoped>
.center {
  flex-grow: 1;
  display: flex;
  // padding-left: 60px;

  // 去除搜索框左侧下拉菜单后增加
  // justify-content: flex-end;

  .left {
    margin-right: 1.875rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    height: 60px;
    border: 1px solid var(--border-color-default);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 18px;
    display: flex;
    align-items: center;
    .font {
      font-size: 0.75rem;
      .p1 {
        color: var(--text-color-minor);
      }
      .p2 {
        color: var(--main-color);
        font-weight: 600;
      }
    }
    .icon {
      margin-left: 24px;
      color: var(--text-color-minor);
    }
  }
  .right {
    flex-grow: 1;

    // 去除搜索框左侧下拉菜单后增加
    // max-width: 550px;
    .descripe{
      font-size: 0.75rem;
      width: 100%;
      color:var(--text-color-minor);

     /deep/ p{
        line-height:2;
      }
    }
  }
}

.headerinput {
  color: var(--text-color-default);
  font-size: 1rem;
  padding: 0 5px;
}
</style>