<template>
  <div class="loginbox">
    <div class="hintinfo" v-if="isshowhitinfo">
      <strong class="foo">Error:</strong> {{ hintInfo }}
    </div>
    <div class="login">
      <div class="loginInfo">
        <div class="group">
          <p class="hint">{{ $my_vue.$t("Passport.Account") }} *</p>
          <has-icon-input
            type="text"
            v-model="loginData.userName"
            :height="40"
          ></has-icon-input>
        </div>

        <div class="group">
          <p class="hint">{{ $my_vue.$t("Passport.Password") }} *</p>
          <has-icon-input
            type="password"
            v-model="loginData.userPassword"
            :height="40"
          ></has-icon-input>
        </div>

        <div class="isremember">
          <div class="fk" @click="remmember">
            <i class="iconfont icon-weigouxuan" v-if="!isRemmber"></i>
            <i class="iconfont icon-yigouxuan" v-else></i>
          </div>
          <span>{{ $my_vue.$t("Passport.Pages.Remember") }}</span>
        </div>

        <div class="btn" @click="login">
          {{ $my_vue.$t("Passport.SignIn") }}
        </div>

        <div class="bottomHit">
          <p class="lostpassword" @click="changeType('findPassword')">
            {{ $my_vue.$t("Passport.Pages.FindPwd") }}?
          </p>
          <p class="lostpassword" @click="changeType('register')">
            {{ $my_vue.$t("Passport.CreateAccount") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HasIconInput from "common/input/HasIconInput";
import changeUserState from "@/common/addFun/disposeUserState";
import User from "model/User.js";
export default {
  data() {
    return {
      //登录是否记住用户
      isRemmber: true,

      //登录数据
      loginData: {
        userName: "",
        userPassword: "",
      },

      //是否显示提示信息
      isshowhitinfo: false,
      //提示的文本
      hintInfo: "",
    };
  },

  props: {
    //是否显示弹框
    isshow: {
      type: Boolean,
    },

    //类型
    type: {
      type: String,
    },
  },

  created() {},

  computed: {
    $my_vue() {
      return $my_vue;
    },
  },

  methods: {
    //切换类别
    changeType(type) {
      this.$emit("update:type", type);
    },

    //点击了记住用
    remmember() {
      this.isRemmber = !this.isRemmber;
    },

    //点击登录按钮
    login() {
      if (this.loginData.userName == "" || this.loginData.userPassword == "") {
        return (() => {
          this.isshowhitinfo = true;
          this.loginData.userName == ""
            ? (this.hintInfo = $my_vue.$t("Passport.Validation.Enternumber"))
            : (this.hintInfo = $my_vue.$t("Passport.Validation.PasswordEmpty"));
        })();
      } else {
        // 请求服务器
        this.goLogin();
      }
    },

    //登录请求
    goLogin() {
      User.Passport.Login({
        data: {
          account: this.loginData.userName,
          password: this.loginData.userPassword,
        },
        succ: (res) => {
          this.disposeUserInfo(res);
        },
        fail: (res, all) => {
          this.isshowhitinfo = true;
          this.hintInfo = all.msg;
        },
      });
    },

    //登录或注册成功后处理用户信息
    disposeUserInfo(res) {
      let payload = {
        username: res.username,
        avatar: res.avatar,
        token: res.token,
      };

      if (this.isRemmber) {
        changeUserState.add(payload, "long");
      } else {
        changeUserState.add(payload, "short");
      }

    },
  },

  components: {
    HasIconInput,
  },
};
</script>
<style lang='scss' scoped>
.loginbox {
  margin-top: 1.25rem;
  //   display: flex;
  width: 35vw;

  .login {
    width: 100%;

    .title {
      font-size: 1rem;
      font-weight: 600;
      color:var(--text-color-minor);
      padding-bottom: 0.75rem;
      border-bottom: 1px solid var(--border-color-default);
      margin-bottom: 1rem;
    }

    .hint {
      color: #71778e;
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
    }

    .group {
      margin-bottom: 1rem;
    }

    .isremember {
      margin-bottom: 0.75rem;
      display: flex;
      color:#71778e;
      font-size: 0.75rem;
      .fk {
        margin-right: 0.4rem;
      }
    }

    .btn {
      background-color: var(--main-color);
      color: white;
      font-size: 0.875rem;
      display: inline-block;
      padding: 0.4rem 1.5rem;
      border-radius: 0.2rem;
      margin-bottom: 0.75rem;
      cursor: pointer;
    }

    .bottomHit {
      display: flex;

      .lostpassword {
        margin-right: 0.4rem;
        font-size: 0.75rem;
        color: var(--minor-color);
        cursor: pointer;
      }
    }
  }
}

.hintinfo {
  margin-bottom: 0.9rem;
  border: 1px solid var(--border-color-default);
  padding: 0.5rem;
  font-size: 0.75rem;
  color:#71778e;
  .foo {
    color: var(--text-color-minor);
    margin-right: 0.3rem;
  }
}
</style>