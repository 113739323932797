<template>
  <div class="bt-spinner"></div>
</template>
<script>
export default {
  name: "Loading",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>
/* BORDER TOP SPINNER */
.bt-spinner {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid #dfdfdf;
  border-top-color: #2bbef9;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>