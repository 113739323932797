<template>
  <div class="storenotice">
    <div class="container">
      {{ $t("Home.Msg.TopHint") }}
      <div class="email">
        <a class="contact" href="mailto:janlen@51tea.com">
          {{ $t("User.Account.Pages.ContactUs") }}
        </a>
      </div>

      <div class="email wechat" @click="showWechatQr">
        <a class="contact" href="#">
          {{ $t("User.Account.Pages.Wechat") }}
        </a>
      </div>
      <!-- <strong class="mobileNum"></strong> -->
    </div>
    <!-- 弹框 -->
    <pop-up :isshow="isshowDialog" width="35vw">
      <div class="pop">
        <div class="header">
          <span>{{ popname }}</span>
          <i class="iconfont icon-guanbi close" @click="close"></i>
        </div>
        <div class="content_f">
          <img class="wechatimg" :src="wechatimg" />
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import PopUp from "common/popup/PopUp";

export default {
  data() {
    return {
      //是否显示弹框
      isshowDialog: false,
      //弹框的文字
      popname: this.$t("User.Account.Pages.Wechat"),
      wechatimg: "https://www.buychinesetea.com/assets/img/wechat.png",
    };
  },
  props: {
    // StoreNoticeInfo:{
    //     type:String,
    //     default:'无广告内容'
    // }
  },
  components: {
    PopUp,
  },
  created() {},
  methods: {
    //关闭弹框
    close() {
      this.isshowDialog = false;
      // this.resetState();
    },
    showWechatQr() {
      this.isshowDialog = true;
    },
  },
};
</script>
<style lang='scss' scoped>
.pop {
  // width: 100%;
  .header {
    // width: 25rem;
    width: 100%;
    box-sizing: border-box;
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    color: var(--text-color-default);
    font-size: 0.875rem;
    padding: 0.3rem 0.2rem;
    align-items: center;
    i {
      cursor: pointer;
    }
  }

  .content_f {
    padding: 2.5rem;
    background-color: white;
    .group {
      .emailBox,
      .changePassword {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .name {
          margin-right: 0.5rem;
          display: block;
          width: 8rem;
          text-align: right;
          font-size: 0.75rem;
          color: var(--text-color-default);
          strong {
            color: var(--red-color);
            margin-right: 3px;
          }
        }
        .captcha {
          height: 30px;
          background-color: var(--red-color);
          color: white;
          width: 10rem;
          text-align: center;
          line-height: 30px;
          font-size: 0.75rem;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .input {
          width: 24rem;
        }
        //当该输入框对应的是验证码时，此输入框应当小一些
        .short {
          width: 14rem;
        }
      }
    }
  }

  @mixin base_btn {
    background-color: var(--red-color);
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }

  .emailBtn {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    span {
      @include base_btn;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2.5rem;
    padding-right: 2.5rem;
    span {
      @include base_btn;
    }
  }
}
.storenotice {
  background-color: var(--bg-color-success);
  .container {
    color: var(--success-color);
    font-size: 0.75rem;
    line-height: 36px;
    height: 36px;
    text-align: center;
    position: relative;
    .email {
      position: absolute;
      right: 187px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 25px;
      line-height: 25px;
      padding: 0 4px;
      // background-color: white;
      a {
        text-decoration: none;
        color: var(--success-color);
      }
    }
    .wechat {
      position: absolute;
      right: 25px;
      cursor: pointer;
    }

    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
  }
  .wechatimg {
    // width:100%
    height: 450px;
  }
}
</style>