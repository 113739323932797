<template>
  <div class="header">
    <store-notice></store-notice>
    <header-top></header-top>
    <header-main></header-main>
    <header-nav ></header-nav>
  </div>
</template>
<script>
import { StoreNotice, HeaderTop, HeaderMain, HeaderNav } from "./child";
import Goods from '@/model/Goods.js'
export default {
  data() {
    return {
      cats:[]
    };
  },
  created() {
    this.getcategory()
  },
  components: {
    StoreNotice,
    HeaderTop,
    HeaderMain,
    HeaderNav,
  },
  methods:{
    //请求分类数据
    getcategory(){
      Goods.Goods.Category({
        data:{
          type:'goods'
        },
          succ: (res, all) => {
            this.cats=res.cats
         
        },
        fail: (res, all) => {},
      })

    }

  }
};
</script>
<style lang='scss' scoped>

.header{
  background-color:var(--bg-color-hint);
  // background-color: white;
}
</style>