<template>
  <transition name="slide-fade"  appear>
    <div :class="[name,type]" v-if="isShow" >
      <!-- 自定义消息提示组件 -->
      <div class="font">
        {{ message }}
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Message",
  data() {
    return {
      isShow: true,


    };
  },
  created() {},
  computed:{
 
  },
  methods:{
    close(){
      this.isShow = false
      setTimeout(()=>{
        this.$destroy()
      },10)
    }
  }
};
</script>
<style lang='scss' scoped>
.my_message {
  position: fixed;
  z-index: 999;
  border-radius: 0.6rem;
  min-width: 1rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0rem 3rem;
  top:50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .font {
    font-size: 0.875rem;
  }
}

// 添加购物车成功
.addCart {
  background-color: rgba(0, 0, 0, 0.7);
  .font {
    color: white;
  }

}

//收藏、取消成功
.setLike {
  background-color: rgba(0, 0, 0, 0.7);
  .font {
    color: white;
  }
}

//普通成功提示
.success {
  background-color: rgba(0, 0, 0, 0.7);
  .font {
    color: white;
  }
}

//各类异常提示
.error {
  background-color: rgba(0, 0, 0, 0.7);
  .font {
    color: white;
  }
}


/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {


  transform:translateX(-50%) translateY(-50%) scale(0.5, 0.5);
  opacity: 0;
}
</style>