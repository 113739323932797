var U = require("../utils/ajax.js");

var List = {
  Lists: function (opt) {
    U.ajax({
      service: 'goods.lists',
      data: {
        path: opt.data.path ? opt.data.path : '',
        keyword: opt.data.keyword ? opt.data.keyword : '',
        sort: opt.data.sort ? opt.data.sort : '',
        limit: opt.data.limit ? opt.data.limit : '10',
        page: opt.data.page ? opt.data.page : '1'
      },
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },

  /*属性筛选*/
  Attrubitions: function (opt) {
    U.ajax({
      service: 'goods.listattrs',
      data: {
        path: opt.data.path ? opt.data.path : '',
        keyword: opt.data.keyword ? opt.data.keyword : ''
      },
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  }
  ,	


};
var Goods = {
  Category: function (opt) {
    U.ajax({
      service: 'category.lists',
      data: {
        "type": opt.data.type
      },
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail ? opt.fail(res, all) : '';
      }
    });
  },
  CategoryNatures: function (opt) {
    U.ajax({
      service: 'category.natures',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail ? opt.fail(res, all) : '';
      }
    });
  },

  
  Detail: function (opt) {
    U.ajax({
      service: 'goods.detail',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
  Fav: function (opt) {
    U.ajax({
      service: 'goods.fav',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      },
      context_this:opt.context_this
    });
  },
  FavList: function (opt) {
    U.ajax({
      service: 'goods.favList',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
 
  Verify: function (opt) {
    U.ajax({
      service: 'goods.verify',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
  Haibao: function (opt) {
    U.ajax({
      service: 'goods.haibao',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
  Comment: function (opt) {
    U.ajax({
      service: 'goods.commentlist',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
  sendComment: function (opt) {
    U.ajax({
      service: 'user.orderComment',
      data: opt.data,
      succ: function (res, all) {
        opt.succ(res, all);
      },
      fail: function (res, all) {
        opt.fail(res, all);
      }
    });
  },
};
module.exports = {
  List,
  Goods
};