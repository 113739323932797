import VueRouter from 'vue-router'

//置顶
function goTop() {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0

}

//解决报错
(function resolveErr() {
    //解决路由重复点击报错问题
    const VueRouterPush = VueRouter.prototype.push
    VueRouter.prototype.push = function push(to) {
        return VueRouterPush.call(this, to).catch(err => err)
    }
    const VueRouterReplace = VueRouter.prototype.replace
    VueRouter.prototype.replace = function replace(to) {
        return VueRouterReplace.call(this, to).catch(err => err)
    }
})()









export { goTop }
