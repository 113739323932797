var U = require("../utils/ajax.js");

var Index = function (opt) {
  U.ajax({
    service: 'page.index',
    data: {
      opt: opt.data,
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail ? opt.fail(res, all) : '';
    }
  });
};
var Common = function (opt) {
  U.ajax({
    service: 'page.common',
    data: {
      opt: opt.data
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail ? opt.fail(res, all) : '';
    }
  });
};

module.exports = {
  Index: Index,
  Common
};