import Cart from "@/model/Cart";
const cart = {
    state: {
        //请求的购物车数据,  begin用于初始化页面时的标记
        cart: {
            carts: [],
            total: {
                total_price_text: "£00.00"
            },
            begin: true
        },

        //当前用户的购买清单
        selgoods: [],

    },

    mutations: {
        //更新购物车数据
        updateCartData(state, payload) {
            state.cart = payload
        },

        //更新用户的购买清单
        setSelgoods(state, payload) {
            if (payload.length == 0) {
                localStorage.setItem('selgoods', JSON.stringify([]))
                return state.selgoods = payload
            }
            let arr = []
            //根据cart_ident渲染对应的列表  
            payload.forEach(val => {
                let res = state.cart.carts.find((item) => {
                    return val == item.cart_ident
                })

                if (res) {
                    arr.push({
                        cart_ident: res.cart_ident,
                        quantity: res.quantity
                    })
                }

            });
            state.selgoods = arr
            localStorage.setItem('selgoods', JSON.stringify(arr))
        },


        // 检测用户本地数据是否为真实,排除不真实的
        check(state, payload) {
            let carts = payload.carts
            if (carts.length == 0){
                localStorage.setItem('selgoods', JSON.stringify([]))
                return
            }
            let selgoods
            try {
                selgoods = JSON.parse(localStorage.getItem('selgoods'))
                if (!my_utils.isArray(selgoods)) {
                    throw 'selgoods is not Array!'
                }
            } catch (err) {
                console.error(new Error(err));
                // 出现异常则重置selgoods
                selgoods = []
                localStorage.setItem('selgoods', JSON.stringify([]))
            }

            if (!selgoods || !selgoods.length) return

            // 去重数组
            let cart_ident_arr = []

            selgoods = selgoods.filter((item) => {
                let res = carts.find((val) => {
                    let is = (val.cart_ident == item.cart_ident)
                    if (is) {
                        item.quantity = val.quantity
                    }
                    return is
                })
                if (!!res) {
                    let res_temp = cart_ident_arr.find((tem_val) => {
                        return tem_val == res.cart_ident
                    })
                    if (!!res_temp) {
                        return false
                    } else {
                        cart_ident_arr.push(res.cart_ident)
                        return true
                    }
                }
                return !!res
            })

            localStorage.setItem('selgoods', JSON.stringify(selgoods))
        }


    },

    actions: {
        //获取购物车列表数据   state, commit, rootState
        getCart(context) {
            // 判断用户当前是否已经登录
            let isLogin = !!context.rootState.user.token
            let data
            if (isLogin) {
                data = {
                    selgoods: JSON.stringify([]),
                }
            } else {
                let allgoods = localStorage.getItem('allgoods')
                if (allgoods && allgoods.length) {
                    data = {
                        allgoods,
                        selgoods:JSON.stringify([]),
                    }
                } else {
                    context.commit('updateCartData', {
                        carts: [],
                        total: {
                            total_price_text: "£00.00"
                        }
                    })
                    return
                }
            }

            return Cart.Detail({
                    data: data,
                    succ: (res,all) => {
                        context.commit('check', res)
                        context.commit('updateCartData', res)
                    },
                    fail: (res,all) => {
                        context.commit('updateCartData', {
                            carts: [],
                            total: {
                                total_price_text: "£00.00"
                            }
                        })
                    }
                });
            
        },

    },
}


export default cart