

// 点击清楚购物车
function clear(opt) {
    localStorage.setItem('allgoods', JSON.stringify([]))

    // 请求购物车数据
    $my_vue.$store
        .dispatch("getCart")
        .then((res) => {
            opt.succ(res)
        })
        .catch((err) => {
            opt.fail(err, err)
        });

}

let method = {

}

let $cart_cartEmpty = function (opt) {
    if (opt.data?.service != 'cart.cartEmpty') return true
    // 判断用户当前是否已登录
    let isRegister = !!$my_vue.$store.state.user.token

    if (isRegister) {
        return method
    } else {
        clear(opt)
        return false
    }

}

export default $cart_cartEmpty