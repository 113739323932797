<template>
  <div class="right">
    <my-icon
      :Iconconfig="IconconfigLeft"
      :avatar="
        $store.state.user.token != '' ? avatar + $store.state.user.avatar : ''
      "
      :isUser="true"
      :chooseIcon="chooseIconLeft"
      @iconBtnClick="uerIsClick"
    ></my-icon>
    <div class="price">
      <!-- <h5>{{ $store.state.user.token != "" ? totalPrice : "£00.00" }}</h5> -->
      <h5>{{ totalPrice }}</h5>
    </div>
    <div
      class="cart"
      @mouseover="cartIsvisible = true"
      @mouseout="cartIsvisible = false"
    >
      <my-icon
        :Iconconfig="IconconfigRight"
        :chooseIcon="chooseIconRight"
        :isshowNum="true"
        :num="
           $store.state.cart.cart.total.goods_num_quant || 0
        "
        @iconBtnClick="shopCarIsClick"
      ></my-icon>

      <drop-down :isvisible="cartIsvisible">
        <div class="productInfo" v-if="!cartIsEmpty">
          <div class="goodList">
            <div
              class="goodItem"
              v-for="item in $store.state.cart.cart.carts"
              :key="item.cart_ident"
            >
              <div class="img">
                <img
                  v-lazy="item.image"
                  alt=""
                  @click="openProduct(item.product_id)"
                />
                <i
                  class="iconfont icon-close1"
                  @click="delProduct(item.cart_ident)"
                ></i>
              </div>
              <div class="buyInfo">
                <div class="goodName" @click="openProduct(item.product_id)">
                  {{ item.name }}
                </div>

                <div class="priceinfo">
                  <span class="num"> {{ item.quantity }} X </span>
                  <span class="price">
                    {{ item.price_text }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="total">
            <strong class="title">{{$t('User.Orders.Pages.GoodsAmount')}}:</strong>
            <strong class="totalPrice">{{ totalPrice }}</strong>
          </div>

          <div class="cartBtn">
            <div class="viewBtn" @click="goToCart">{{$t('Cart.Pages.MyCart')}}</div>
            <!-- <div class="checkout" @click="goCheckout">{{$t('Home.Button.Checkout')}}</div> -->
          </div>
        </div>

        <div class="empty" v-if="cartIsEmpty">
          <div class="pic">
            <i class="iconfont icon-Cart"></i>
          </div>
          <p class="cartEmpty">{{$t('Cart.Pages.CartEmpty')}}</p>
        </div>

        <p class="cartHit">
          <!-- <span> We reduce shipping prices to only 2.49 €!</span> -->
        </p>
      </drop-down>
    </div>
  </div>
</template>
<script>
import MyIcon from "common/icon/MyIcon";
import { config } from "@/utils/config";
import Cart from "@/model/Cart";
import { DropDownMenu, DropDown } from "common/dropDown";
export default {
  data() {
    return {
      //左右图标的类名
      chooseIconLeft: "icon-user",
      chooseIconRight: "icon-shop-cart-",
      //左右图标的配置
      IconconfigLeft: {
        width: "2.625rem",
        height: "2.625rem",
        lineHeight: "2.625rem",
      },
      IconconfigRight: {
        width: "2.625rem",
        height: "2.625rem",
        lineHeight: "2.625rem",
        backgroundColor: "#fff1ee",
        color: "#ea2b0f",
        borderColor: "#fff1ee",
      },
      //当前购物车购买的数量
      shopCarNum: 0,

      //用户头像
      avatar: config.SERVER_PRODUCTION.replace("/api", ""),

      //是否显示购物车弹框
      cartIsvisible: false,
    };
  },
  created() {
    //获取购物车列表数据
    this.$store
      .dispatch("getCart")
      .then((res) => {})
      .catch((err) => {});
  },
  components: {
    MyIcon,
    DropDown,
  },

  computed: {
    //总价
    totalPrice() {
      if (this.$store.state.cart.cart.carts.length == 0) {
        return "£00.00";
      } else {
        let price = this.$store.state.cart.cart.total.total_price_text;
        return price;
      }
    },

    //购物车是否为空
    cartIsEmpty() {
      // if (this.$store.state.user.token != "") {
        if (this.$store.state.cart.cart.carts.length == 0) {
          return true;
        } else {
          return false;
        }
      // }
      return true;
    },
  },

  mounted() {
    // this.randerAvatar();
  },
  methods: {
    //点击购物车中的商品
    openProduct(product_id) {
      this.$router.push({
        path: "/product/index",
        query: {
          product_id,
        },
      });
    },

    //点击view cart
    goToCart() {
      this.$router.push({
        path: "/cart/index",
        query: {},
      });

      this.cartIsvisible = false
    },

    // 去结算
    goCheckout() {
        this.cartIsvisible = false
      let cart = this.$store.state.cart.cart.carts;
      let selgoods = localStorage.getItem("selgoods");
      if (cart.length == 0) {
        return this.$message("error", this.$t('Cart.Pages.CartEmpty'), this, 2000);
      } 
      else if(!selgoods || selgoods.length == 0){
        // 如果没有selgoods或长度为0则将所有商品置入
        let arr = []
        cart.forEach(val => {
          arr.push(val.cart_ident)
        });
        this.$store.commit('setSelgoods',arr)
        selgoods = localStorage.getItem("selgoods");
      }

      //获取结算页信息
      Cart.Checkout({
        data: {
          selgoods:selgoods,
          is_fastbuy: "0",
        },
        succ: (res) => {
          this.$router.push({
            path: "/check/index",
          });
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
        
      });
    },

    //用户头像被点击
    uerIsClick() {
      this.$router.push({
        path: "/account/index",
        query: {},
      });
    },
    //购物车被点击
    shopCarIsClick() {
      this.$router.push({
        path: "/cart/index",
        query: {},
      });
    },

    //删除一个商品
    delProduct(cart_ident) {
      // console.log(cart_ident)
      let delgood = "[" + '"' + cart_ident + '"' + "]";

      Cart.Delete({
        data: {
          cart_ident: delgood,
          selgoods: "[]",
        },
        succ: (res) => {
          console.log(res);
          this.$store.commit("updateCartData", res);
        },

        fail: (res) => {
          console.log(res);
        },

        
      });
    },

    //渲染用户头像
    randerAvatar() {
      let base = config.SERVER_PRODUCTION.replace("/api", "");
      let storeAvatar = this.$store.state.user.avatar;
      let sessAvatar = sessionStorage.getItem("avatar");
      let locAvatar = localStorage.getItem("avatar");
      if (storeAvatar != "") {
        return (this.avatar = base + storeAvatar);
      } else if (!!locAvatar) {
        return (this.avatar = base + locAvatar);
      } else if (!!sessAvatar) {
        return (this.avatar = base + sessAvatar);
      } else {
        return (this.avatar = "");
      }
    },
  },
};
</script>
<style lang='scss' scoped>

.right {
  margin-left: 30px;
  display: flex;
  align-items: center;
  .price {
    color: var(--text-color-default);
    margin: 0 15px;
    font-size: 1rem;
  }
}

.cart {
  position: relative;
  cursor: pointer;
  .goodList {
    width: 18.75rem;

    padding: 3px 8px;
    max-height: 18.75rem;
    overflow: auto;
    .goodItem {
      display: flex;
      padding: 14px 8px;
      border-bottom: 1px solid var(--border-color-default);
      .img {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 1.5rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        i {
          position: absolute;
          top: -0.5rem;
          left: -0.875rem;
          color: var(--red-color);
          font-size: 1rem;
        }
      }

      .buyInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .goodName {
          font-size: 0.75rem;
          color: var(--text-color-default);
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          max-width: 12rem;
          cursor: pointer;

          &:hover {
            color: var(--main-color);
          }
        }

        .priceinfo {
          .num {
            font-size: 0.75rem;
            color: var(--text-color-default);
          }

          .price {
            font-size: 0.75rem;
            color: var(--red-color);
          }
        }
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 8px;
    align-items: center;

    .title {
      font-size: 0.875rem;
      color: #d1d1dd;
    }
    .totalPrice {
      font-size: 1rem;
      color: var(--red-color);
    }
  }

  .cartBtn {
    padding-left: 8px;
    padding-right: 8px;

    // 去除底部描述后加
    padding-bottom:15px;

    .viewBtn {
      height: 2.75rem;
      border: 1px solid var(--border-color-default);
      line-height: 2.75rem;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.875rem;
      color: var(--text-color-default);
      background-color: white;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }

    .checkout {
      cursor: pointer;
      height: 2.75rem;
      line-height: 2.75rem;
      text-align: center;
      font-size: 0.875rem;
      color: white;
      background-color: var(--red-color);
    }
  }

  .empty {
    .pic {
      margin: 1.5rem auto;
      width: 3.5rem;
      height: 3.5rem;
      background-color: #eaecef;
      border-radius: 50%;
      text-align: center;
      line-height: 3.5rem;
      i {
        color: var(--red-color);
        font-size: 1.875rem;
      }
    }

    .cartEmpty {
          max-width: 18.75rem;
    width: 18.75rem;
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      text-align: center;
    }
  }

  .cartHit {
    padding-left: 8px;
    padding-right: 8px;
    max-width: 18.75rem;
    width: 18.75rem;
    span {
      display: inline-block;
      font-size: 0.75rem;
      color: var(--text-color-default);
      padding: 1rem 0;
      margin-top: 0.875rem;
      width: 100%;
      text-align: center;
      border-top: 1px solid var(--border-color-default);
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分文字以...显示*/
    }
  }
}
</style>