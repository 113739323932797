<template>
  <div class="input" ref="input" :style={border:border}>
    <div class="rightbtn" v-if="issetLefticonBtn">
      <slot name="leftBtn">
      </slot>
    </div>
    <input
      :type="type"
      class="myinput"
      :style="{color,fontSize}"
      ref="myinput"
      :placeholder="placeholder"
      :value="value"
      @input="handInput"
    />
    <div class="btn" ref="btn" v-if="issetRighticonBtn" @click="btnClick" :style="{backgroundColor:rightBtnBgColor}">
      <slot name="rightBtn">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      //设置输入框的高度
      this.$refs.input.style.height = this.height + "px";
      //设置背景颜色
      //this.$refs.input.style.backgroundColor = this.backgroundColor
      //this.$refs.myinput.style.backgroundColor = this.backgroundColor
      //设置圆角
      this.$refs.input.style.borderRadius = this.radius
    },
    //点击了按钮
    btnClick() {
      this.$emit("InputBtnClick");
    },

    //输入框文字发生了变化
    handInput(e) {
      this.$emit("input", e.target.value);
    },
  },

  props: {
    //高度
    height: {
      type: Number,
      default: 60,
    },
    // 输入框内文字颜色
     color:{
       type:String,
       default:'#6c757d'
    },

    // 文字大小
    fontSize:{
      type:String,
      default:"12px"
    },

    //类型
    type: {
      type: String,
      default: "text",
    },

    //父组件传递的值
    value: {
      type: String,
      default: "",
    },

    //背景颜色
    backgroundColor: {
      type: String,
      default: "#f3f4f7",
    },

    //是否设置右侧按钮
    issetRighticonBtn: {
      type: Boolean,
      default: false,
    },

    // 右侧按钮背景色
    rightBtnBgColor:{
      type:String
    },

    //是否设置左侧按钮
    issetLefticonBtn: {
      type: Boolean,
      default: false,
    },
    //默认提示文字
    placeholder: {
      type: String,
      default: "",
    },
    //配置圆角
    radius:{
      type:String,
      default:''
    },

    //配置边框
    border:{
      type:String
    }

  },
};
</script>
<style lang='scss' scoped>

.input {
    input::-webkit-input-placeholder {
      color: #b6b6b6;
    }
    input::-moz-input-placeholder {
      color: #b6b6b6;
    }
    input::-ms-input-placeholder {
      color: #b6b6b6;
    }
  position: relative;
  display: flex;
  padding:4px;
  box-sizing: border-box;
  .myinput {
    height: 100%;
    width: 100%;
    border:1px solid #DCDFE6;
    border-radius: 4px;
    // color: #888;
    // font-size: 0.75rem;
    outline: none;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .btn,.rightbtn {
    display: flex;
    align-items: center;

  }


}
</style>