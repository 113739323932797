<template>
  <div class="star" ref="star">
    <i class="iconfont icon-star" v-for="item in showStarNum" :key="item"></i>
    <!-- <span>{{ starNum}}</span> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.set();
  },
  methods: {
    //设置星星的等级
    set() {
      let child = this.$refs.star.children;
      for (let i = 0; i < this.starNum; i++) {
        child[i].classList.add("active");
      }
    },
  },
  props: {
    //星星等级
    starNum: {
      type: Number,
      default: 0,
    },
    //显示多少星星
    showStarNum: {
      type: Number,
      default: 5,
    },
  },
};
</script>
<style lang='scss' scoped>


.star {
  i,span{
    color: #d9d9e9;
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }
  .active {
  color: #ffcd00;
}
}


</style>