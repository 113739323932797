const axios = require('axios');
const cf = require("./config.js");
const noLogin = require('@/common/addFun/noLogin').default
const qs = require('qs');

//封装ajax-
function ajax(opt) {
    opt = opt || {};
    opt.method = opt.method ? opt.method.toUpperCase() : 'POST';
    opt.url = opt.url || '';
    opt.header = opt.header || {
      'content-type': 'application/x-www-form-urlencoded'
    },
      opt.data = opt.data || {};
    opt.data.service = opt.service || "";
  
    !!localStorage.getItem('token') ? opt.data.token = localStorage.getItem('token') : opt.data.token = sessionStorage.getItem('token')//给service用
  
    opt.success = opt.success || function () { };
    opt.fail = opt.fail || function () { };
  
    let lang = localStorage.getItem("lang");
    if (lang) {
      opt.lang = lang
      lang == 'zh' && (() => { lang = 'zh-cn' })()
    } else {
      lang = 'en'
      opt.lang = lang
    }
    let url = cf.config.SERVER_PRODUCTION + '?service=' + opt.data.service + '&lang=' + lang;
    const options = {
      method: opt.method,
      headers:opt.header,
      data: qs.stringify(opt.data),
      url: url,
    };
  
    // 是否需要网络请求,如果不需要网络请求则返回false
    isNetwork = null       
      // 在请求数据的同时做一部分拦截操作
      let arr = ['$cart_add','$user_login','$user_register','$cart_Update','$cart_delete','$cart_cartEmpty']
      arr.forEach((item)=>{
        let res = $my_vue.$addFun[item](opt)
        if(res != true){
          isNetwork = res
        }
      })
    if (isNetwork === false) return Promise.resolve()
    
    // 拦截过程中可能会对请求的数据做一些添加操作，需要更新为最新的数据
    options.data = qs.stringify(opt.data)
    return new Promise((resolve, reject) => {
      axios(options).then(res => {
        if (res.data.code == 1) {
          opt.succ(res.data.data, res.data);
          typeof isNetwork?.success == 'function' && isNetwork.success(opt, res.data.data, res.data)
          resolve(res.data.data)
        }
        else {
          reject(res.data)
          if (res.data.msg == 'Need login' || res.data.msg == '请先登录' || res.data.msg == '请登录') {
            opt.fail(res.data.data, res.data);
            noLogin()
            return;
          }
          else {
            opt.fail(res.data.data, res.data);
          }
        }
  
      }).catch(err => {
        console.log(err);
        let msg = err.msg || '请求失败！'
        opt.fail(err, { msg });
        reject({msg})
      });
    })
  }

  module.exports = {
    ajax
  }