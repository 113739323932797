<template>
  <div :class="[className]" style="overflow: hidden">
    <div class="swiper-wrapper" ref="swiper">
      <slot></slot>
    </div>
    <div class="ishidden" v-if="!ishidden">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mySwiper: null,
    };
  },

  watch: {
    "mySwiper.realIndex": {
      handler(newIndex) {
        this.$emit("change", newIndex);
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    //配置项
    options: {
      type: Object,
      default: () => {},
    },

    //绑定的class
    className: {
      type: String,
      default: "swiper-container",
    },

    //是否隐藏左右切换按钮
    ishidden: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.mySwiper = new Swiper("." + this.className, this.options);
    },
  },
};
</script>
<style lang='scss' scoped>
</style>