import Vue from 'vue'
import $cart_add from './cart_add.js'
import  $user_login from './user_login'
import  $user_register from './user_register'
import  $cart_Update from './cart_Update'
import  $cart_delete from './cart_delete'
import  $cart_cartEmpty from './cart_cartEmpty'


Vue.prototype.$addFun = {
    $cart_add,
    $user_login,
    $user_register,
    $cart_Update,
    $cart_delete,
    $cart_cartEmpty
}