<template>
  <span class="tag" ref="tag" :class="[`tag-${type}`]">
    <slot></slot>
  </span>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    //是否加圆角
    isaddcircular: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isaddcircular) {
      this.$refs.tag.classList.add("isaddcircular");
    }
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.tag-success {
  color: #00b86c;
  background-color: #e5f8ed;
  text-align: center;
  padding: 0.25rem 0.625rem;
  font-size: 0.75rem;
}

.isaddcircular {
  border-radius: 0.6rem;
}
</style>