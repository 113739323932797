<template>
  <div id="app">
    <shop-header></shop-header>
    <router-view ref="activeCom"></router-view>
    <shop-footer></shop-footer>
    <product-pop v-if="$store.state.product_id.isOpen" :product_id="$store.state.product_id.val"></product-pop>
    <back-top></back-top>
  </div>
</template>
<script>
import ShopHeader from 'content/header/ShopHeader' 
import ShopFooter from 'content/footer/ShopFooter'
import ProductPop from 'content/product/ProductPop'
import BackTop from 'common/backTop/BackTop'
import { Common} from '@/model/Page.js'
export default {
  data() {
    return {
      isshow:false
    };
  },
  created() {
    // 创建一个全局对象用来保存当前组件
    globalThis.$my_vue = this
    this.getCommonData()
  },
  components: {
    ShopHeader,
    ShopFooter,
    ProductPop,
    BackTop
  },
  methods: {
    // 请求顶部和底部公用的数据接口
    getCommonData(){
      Common({
        succ:(res)=>{
          this.$store.commit('updateMenu',res)
        },
        fail:()=>{}
      })
    }

  },
};
</script>
<style lang='scss'>
@import "assets/scss/base.scss";

.el-cascader-panel {
    height: 300px;
}

</style>