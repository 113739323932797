// 添加用户本地信息,临时或长久
function add(userInfo,type){
    if(type == 'short'){
        sessionStorage.setItem("token",userInfo.token)
        sessionStorage.setItem("username",userInfo.username)
        sessionStorage.setItem("avatar",userInfo.avatar)
    }else if(type == 'long'){
        localStorage.setItem("token",userInfo.token)
        localStorage.setItem("username",userInfo.username)
        localStorage.setItem("avatar",userInfo.avatar)
    }
    $my_vue.$store.commit('updateUserInfo', {
        username:userInfo.username,
        token:userInfo.token,
        avatar:userInfo.avatar
    })

    location.href = '/'

}

// 去除用户本地信息
function remove(){
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("avatar")
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("avatar")
        $my_vue.$store.commit('updateUserInfo', {
            username:'',
            token:'',
            avatar:''
        })

        // location.href = '/'
}

let changeUserState = {
    add,
    remove
}


export default changeUserState