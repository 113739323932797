import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './route'
import {check_view} from './rule/check_view'
import {paycenter_view} from './rule/paycenter_view'
import {account_view} from './rule/account_view'
import {goTop} from './rule/base_rule'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => { 
  document.title = "51tea" 
  check_view(to,from,next)
  paycenter_view(to,from,next)
  account_view(to,from,next)
  goTop()
  next()
})


export default router




