<template>
  <div class="DefLoading" v-if="isshow" :style="setStyle">
    <loading></loading>
  </div>
</template>
<script>
import Loading from "../type/Loading";
export default {
  name: "DefLoading",
  data() {
    return {};
  },
  methods: {},
  computed: {},
  props: {
    isshow: {
      type: Boolean,
    },
    setStyle:{
      type:Object
    }
  },
  created() {},
  components: {
    Loading,
  },
};
</script>
<style lang='scss' scoped>
.DefLoading {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
