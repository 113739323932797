<template>
  <div
    class="backTop"
    v-show="isShow"
    :style="{ right, bottom }"
    @click="goTop"
  >
    <i class="iconfont icon-jiantou-copy-copy-copy"></i>
  </div>
</template>
<script>
export default {
  name: "BackTop",
  data() {
    return {
      isShow: false,
    };
  },
  created() {},
  computed: {
    right() {
      return this.position.right + "px";
    },
    bottom() {
      return this.position.bottom + "px";
    },
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop;
      if (top >= this.visibilityHeight) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    goTop() {
      this.move();
    },

    move() {
      let animal = () => {
        let top = document.documentElement.scrollTop;
        let length = top - Math.ceil(top / 10);
        if (length <= 1) {
          window.scrollTo(0, 0);
          return clearInterval(timer);
        }
        window.scrollTo(0, length);
      };

      let timer = setInterval(() => {
        animal();
      }, 15);
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  props: {
    // 位置
    position: {
      type: Object,
      default: () => {
        return {
          right: 30,
          bottom: 30,
        };
      },
    },

    //滑动多远显示
    visibilityHeight: {
      type: Number,
      default: 500,
    },
  },
};
</script>
<style lang='scss' scoped>

.backTop {
  position: fixed;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  width: 3rem;
  border-radius: 50%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 2;
  cursor: pointer;
  color: var(--main-color);
  transition: transform 0.5s;
  &:hover {
    transform: translateY(-2px);
  }
}
</style>