export default {
    state: {
        //    提交评论的默认用户名
        userCommentName:localStorage.getItem("userCommentName") || '',
        // 提交评论的默认邮箱
        userCommentEmail:localStorage.getItem("userCommentEmail") || ''
    },

    getters:{
        getUserCommentName(state){
            return state.userCommentName
        },
        getUserCommentEmail(state){
            return state.userCommentEmail
        },
    },

    mutations: {
        setUserCommentName(state, payload) {
            state.userCommentName = payload
        },
        setUserCommentEmail(state, payload) {
           state.userCommentEmail = payload
        },

    },
}


