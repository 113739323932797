<template>
<div>
</div>
</template>
<script>
export default {
data(){
return {
}
},
created(){
},
}
</script>
<style lang='scss' scoped>
</style>