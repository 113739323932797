<template>
  <div class="leftprice">
    <div class="title">
      <h3 class="price">{{ price }}</h3>
      <h3 class="nature">{{ nature }}</h3>
    </div>
    <div class="tag">
      <Tag type="success" :isaddcircular="true">{{
        $t("Product.Pages.SaleCount") + "：" + goodDetilData.sell_count
      }}</Tag>
    </div>
    <p class="decripe">{{ $cl("brief", goodDetilData, $i18n.locale) }}</p>

    <!-- 商品属性 -->
    <div class="Spec">
      <specification
        :specArr="specArr"
        :specSku="specSku"
        @changeChoose="changeSku"
      ></specification>
    </div>

    <div class="buyBtn">
      <div class="countNum">
        <my-icon
          :Iconconfig="Iconconfig"
          chooseIcon="icon-jianhao"
          @iconBtnClick="buyNum > 1 ? buyNum-- : function () {}"
        ></my-icon>
        <!-- <span class="num">{{ buyNum }}</span> -->
        <input class="inputNum" type="text" v-model="buyNum" />
        <my-icon
          :Iconconfig="Iconconfig"
          chooseIcon="icon-add"
          @iconBtnClick="buyNum++"
        ></my-icon>
      </div>

      <div class="addcart" @click="addToCart">
        {{ $t("Goods.Pages.AddToCart") }}
      </div>
    </div>

    <div class="product-actions">
      <div class="addlike" @click="giveLike">
        <span class="likeBox" :class="{ hasLike: ishasLike }">
          <span
            ><i class="iconfont icon-like"></i
            >{{ $t("Goods.Pages.Wish") }}</span
          >
        </span>
      </div>
    </div>

    <!-- <div class="category"><span>Category</span><a href="#"></a></div>

    <div class="share">
      <a href="#">
        <my-icon
          :Iconconfig="shareIconconfig"
          chooseIcon="icon-fackbook"
        ></my-icon>
      </a>
      <a href="#">
        <my-icon
          :Iconconfig="shareIconconfig"
          chooseIcon="icon-fackbook"
        ></my-icon>
      </a>
      <a href="#">
        <my-icon
          :Iconconfig="shareIconconfig"
          chooseIcon="icon-fackbook"
        ></my-icon>
      </a>
      <a href="#">
        <my-icon
          :Iconconfig="shareIconconfig"
          chooseIcon="icon-fackbook"
        ></my-icon>
      </a>
      <a href="#">
        <my-icon
          :Iconconfig="shareIconconfig"
          chooseIcon="icon-fackbook"
        ></my-icon>
      </a>
    </div> -->
  </div>
</template>
<script>
import Tag from "common/tag/Tag.vue";
import MyIcon from "common/icon/MyIcon";
import Cart from "@/model/Cart";
import Goods from "@/model/Goods";
import Specification from "./leftPriceChild/Specification.vue";
export default {
  data() {
    return {
      Iconconfig: {
        width: "2.75rem",
        height: "2.75rem",
        lineHeight: "2.75rem",
        backgroundColor: "#edeef5",
        color: "#272435",
        fontSize: "1rem",
        border: "none",
      },

      shareIconconfig: {
        fontSize: "0.9375rem",
        width: "2.125rem",
        height: "2.125rem",
        lineHeight: "2.125rem",
        borderRadius: " 50%",
        backgroundColor: "#3b5998",
        color: "white",
      },

      //购买数量
      buyNum: 1,

      //当前商品是否已经被收藏
      isgiveLike: this.goodDetilData.is_fav,

      // 当前商品的选项数据
      sku: {},
    };
  },
  components: {
    Tag,
    MyIcon,
    Specification,
  },

  props: {
    goodDetilData: {
      type: Object,
    },
    specArr: {
      type: Array,
      default: () => [],
    },

    specSku: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    //是否已经收藏过了
    ishasLike: {
      get() {
        return this.isgiveLike;
      },
      set(value) {
        this.isgiveLike = value;
        return value;
      },
    },

    // 价格
    price() {
      let price = this.sku.price_text;
      if (price) return price;
      return this.goodDetilData.price_text;
    },

    nature() {
      if (this.goodDetilData.name) {
        let name = this.goodDetilData.name;
        let reg = /\((.+?)\)/gi;
        let match = name.match(reg);
        if (match) return match[0].replace(/\(|\)/g, "");
      }
      return ''
    },
  },

  methods: {
    // 修改了商品规格
    changeSku(data) {
      this.sku = data;
      this.$emit("changeSku", data);
      this.$parent.$parent.$refs.product_header.goodDetilData_tem = data;
    },

    checkLegal() {
      let newNum = this.buyNum;
      // 检查数字是否合法
      if (newNum == "") {
        this.buyNum = 1;
        this.$message("error", "输入有误！");
        return false;
      }
      let reg = /[^(0-9)]/;
      if (reg.test(newNum)) {
        this.buyNum = 1;
        this.$message("error", "存在非数字！");
        return false;
      }

      return true;
    },
    //添加进购物车
    addToCart(e) {
      if (!this.checkLegal()) return;
      Cart.Add({
        data: {
          goods_id: this.goodDetilData.goods_id,
          product_id: this.sku.product_id || this.goodDetilData.product_id,
          num: this.buyNum,
          type: "goods",
          e,
        },
        succ: (res, all) => {
          this.$store.commit("updateCartData", res);
          // this.$message("addCart", all.msg);
          this.$myPopover(e);

          // 真实的购物车数据
          // let cartList = res.carts;
          // let self_cart_ident = "goods_" + goods_id + "_" + product_id;

          // this.$cart_add(cartList,self_cart_ident)
        },
        fail: (res, all) => {
          this.$message("addCart", all.msg, 2000);
        },
      });
    },

    //收藏商品
    giveLike() {
      let goods_id = this.goodDetilData.goods_id;
      Goods.Goods.Fav({
        data: {
          goods_id,
        },
        succ: (res, all) => {
          //收藏行为
          if (res.fav_status == 1) {
            this.ishasLike = true;
            this.$message("setLike", all.msg);
          } else {
            this.ishasLike = false;
            this.$message("setLike", all.msg);
          }
        },
        fail: (res, all) => {
          this.$message("setLike", all.msg, 2000);
        },
      });
    },
  },
  created() {
    // Cart.Total({
    //   succ:(res)=>{
    //     console.log(res)
    //   }
    // })
  },
};
</script>
<style lang='scss' scoped>
.leftprice {
  widows: 100%;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0.9375rem;
    .price {
      font-size: 1.625rem;
      color: var(--red-color);
    }

    .nature {
      // font-weight: 600;
      font-weight: bolder;
      margin-left: 1rem;
      font-size: 1rem;
      padding: 0.3rem 1.5rem;
      color:#333;
      background-color: #edeef5;
      border-radius:16px;
    }
  }

  .tag {
    margin-bottom: 0.9375rem;
  }

  .decripe {
    font-size: 0.8125rem;
    color: var(--text-color-title);
    line-height: 1.5rem;
    margin-bottom: 0.9375rem;
  }

  .Spec {
    margin-bottom: 20px;
  }

  .buyBtn {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    .countNum {
      display: flex;
      align-items: center;
      margin-right: 1.2rem;
      .num {
        width: 2.5rem;
        text-align: center;
        color: var(--text-color-title);
      }
    }

    .addcart {
      flex-grow: 1;
      text-align: center;
      height: 2.75rem;
      max-width: 10rem;
      line-height: 2.75rem;
      color: white;
      font-size: 0.825rem;
      background-color: var(--main-color);
      //   max-width: 200px;
      border-radius: 1.875rem;
      cursor: pointer;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      &:hover {
        background-color: var(--main-hover-color);
      }
    }
  }

  .product-actions {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color-default);
    padding-bottom: 1.875rem;
    .addlike {
      .likeBox {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 2.0625rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        border: 1px solid var(--border-color-default);
        border-radius: 2.0625rem;
        cursor: pointer;
        span {
          font-size: 0.75rem;
          color: var(--text-color-minor);
        }
      }

      .hasLike {
        background-color: var(--main-color);
        span {
          color: white;
        }
      }
    }

    .Compare {
      margin-left: 1.25rem;
      span {
        font-size: 0.75rem;
        color: var(--text-color-minor);
      }
    }
  }

  .category {
    padding-top: 1.5rem;
    span {
      font-size: 0.75rem;
      color: var(--text-color-minor);
    }
    a {
      font-size: 0.75rem;
      color: var(--text-color-minor);
      text-decoration: none;
    }
  }

  .share {
    margin-top: 2.5rem;
    display: flex;
    a {
      margin-left: 0.3125rem;
      text-decoration: none;
    }
  }
}

.inputNum {
  border: none;
  width: 2.5rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
  outline: none;
  text-align: center;
}
</style>