<template>
  <div class="icon" ref="icon" @click="iconBtnClick">
    <img v-lazy="setImg" v-if="avatar != ''" alt="" />
    <i v-if="showimgori" class="iconfont" :class="chooseIcon"></i>
    <slot></slot>
    <div class="num" v-if="isshowNum">{{ num }}</div>
  </div>
</template>
<script>
export default {
  name: "MyIcon",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.set();
  },

  computed: {
    showimgori() {
      return this.chooseIcon != "" && this.avatar == "";
    },

    setImg() {
      if (this.isUser) {
        return {
          error: require("assets/img/imgState/people.png"),
          loading: require("assets/img/imgState/loading.png"),
          src: this.avatar,
        };
      }else{
        return {
            error: require("assets/img/imgState/error.png"),
          loading: require("assets/img/imgState/loading.png"),
          src: this.avatar,
        }
      }
    },
  },
  methods: {
    //配置图标组件
    set() {
      let icon = this.$refs.icon;
      for (let item in this.Iconconfig) {
        icon.style[item] = this.Iconconfig[item];
      }
    },

    //按钮被点击了
    iconBtnClick() {
      this.$emit("iconBtnClick");
    },
  },
  props: {
    //配置项
    Iconconfig: {
      type: Object,
      default: function () {
        return {
          width: "30px",
          height: "30px",
          border: "1px solid #ccc",
        };
      },
    },
    //选择的图标
    chooseIcon: {
      type: String,
      default: "",
    },

    //是否显示数量图标
    isshowNum: {
      type: Boolean,
      default: false,
    },

    //用户头像
    avatar: {
      type: String,
      default: "",
    },

    //图标的数字
    num: {
      type: Number,
      default: 0,
    },


    // 当前的图片是否为用户
    isUser: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='scss' scoped>

.icon {
  text-align: center;
  background-color: white;
  border: 1px solid #e3e4e6;
  color: #424245;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  .num {
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: #ea2b0f;
    color: white;
    text-align: center;
    top: 2px;
    right: -4px;
    z-index: 7;
    line-height: 17px;
    font-size: 0.75rem;
    border-radius: 50%;
  }
}

i {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  top: 0;
  left: 0;
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
</style>