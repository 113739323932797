<template>
  <div class="pullmenuItem" @click="choose">
    <div class="item">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
   name:'PullMenuItem',
  data() {
    return {};
  },
  props: {
    chooseItem: {
      type: String,
    },
  },
  methods: {
    choose() {
      this.$emit("choose", this.chooseItem);
    },
  },
};
</script>
<style lang='scss' scoped>

.item {
  font-size: 0.75rem;
  color: #888;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: #2bbef9;
  }
}
</style>