<template>
  <div class="Specification">
    <div class="spec" v-if="specArr.length">
      <div class="choose"></div>
      <div class="list">
        <div class="item" v-for="item in specArr" :key="item.spec_id">
          <div class="title">{{$cl('spec_name',item,$i18n.locale)}}</div>
          <div class="specItemBox">
            <div
              class="specItem"
              v-for="val in item.spec"
              :key="val.spec_value_id"
              @click="changeChoose(val, item)"
              :class="{
                disabled: val.disabled,
                actived: !!choose.find((item) => item == val.spec_value_id),
              }"
            >
              <span>{{ val.spec_value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Specification",
  data() {
    return {
      // 当前的选项
      choose: [],
    };
  },

  watch: {
    choose: {
      handler(newVal) {
        if (this.choose.length) {
          this.$emit("changeChoose", this.chooseDetail);
        }
      },
      deep: true,
    },
  },

  computed: {
    // 当前用户选择的商品详情
    chooseDetail() {
      let res = this.specSku.find((item) => {
        let spec_desc = item.spec_desc;
        return (
          this.choose.filter((val) => {
            return spec_desc.includes(val + "");
          }).length == spec_desc.length
        );
      });
      return res;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getIsChecked();
    },

    // 获取默认的选择
    getIsChecked() {
      this.choose = this.specArr.map((item) => {
        return item.spec.find((val) => val.IsChecked).spec_value_id;
      });
    },

    // 改变选择
    changeChoose(data, all) {
      let new_spec_value_id = data.spec_value_id;
      // 查看当前的选项中有无该项
      let has = this.choose.find((val) => val == new_spec_value_id);
      if (has) return;

      // 判断当前元素是否为禁用
      let isDesabled = data.disabled;
      if (isDesabled) return;

      // 如果没有该项则从选项中找到需要替换的旧值
      let allSpec = all.spec;
      let index = this.choose.findIndex((item) => {
        let res = false;
        res = !!allSpec.find((val) => item == val.spec_value_id);
        return res;
      });

      if (index != -1) {
        this.choose.splice(index, 1, new_spec_value_id);
      } else {
        this.choose.push(new_spec_value_id);
      }
    },
  },

  props: {
    specSku: {
      type: Array,
    },
    specArr: {
      type: Array,
    },
  },
};
</script>
<style lang='scss' scoped>
.choose {
  border-top: 1px solid var(--border-color-default);
  padding: 10px 0;
  margin-top: 20px;
}
// .Specification{
//      border-bottom: 1px solid var(--border-color-default);
// }
.list {
  .item {
    margin-bottom: 20px;
    .title {
      color: var(--text-color-title);
      font-size: var(--font-size-base);
      padding: 15px 0;
    }
    .specItemBox {
      display: flex;
      flex-wrap: wrap;
      .specItem {
        cursor: pointer;
        margin-bottom: 10px;
        height: 30px;
        margin-right: 20px;
        line-height: 30px;
        border-radius: 10px;
         color: var(--text-color-title);
        font-size:var(--font-size-base);
        padding: 0px 30px;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        position: relative;
      }

      .disabled {
        background-color: var(--bg-color-disabled);
        border-color: var(--bg-color-disabled);
        color:var(--text-color-minor);
      }

      .actived {
        border-color: var(--border-color-default);
        background-color: var(--main-color);
        color: white;
      }
    }
  }
}
</style>
