<template>
  <div class="myregister">
    <div class="hintinfo" v-if="isshowhitinfo">
      <strong class="foo">Error:</strong> {{ hintInfo }}
    </div>
    <div class="register">
      <div class="registerInfo">
        <!-- <div class="group">
          <p class="hint">{{ $my_vue.$t("Passport.Mobile") }} *</p>
          <has-icon-input
            type="text"
            v-model="registerData.userMobile"
            :height="40"
          ></has-icon-input>
        </div> -->

        <div class="group">
          <p class="hint">{{ $my_vue.$t("Passport.Email") }} *</p>
          <has-icon-input
            type="email"
            v-model="registerData.userEmail"
            :height="40"
          ></has-icon-input>
        </div>

        <div class="group">
          <p class="hint">{{ $my_vue.$t("Passport.Password") }} *</p>
          <has-icon-input
            type="password"
            v-model="registerData.userPassword"
            :height="40"
          ></has-icon-input>
        </div>

        <div class="group">
          <p class="hint">
            {{ $my_vue.$t("Passport.Validation.RePasswordEmpty") }} *
          </p>
          <has-icon-input
            type="password"
            v-model="registerData.userConfirmPassword"
            :height="40"
          ></has-icon-input>
        </div>

        <div class="group captcha">
          <img :src="captcha" alt="" @click="getcaptcha" />
          <has-icon-input
            type="text"
            v-model="registerData.userVcode"
            :height="40"
          ></has-icon-input>
        </div>
        <!-- 
        <p class="reminder">
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our privacy policy.
        </p> -->
        <div class="btn" @click="register">
          {{ $my_vue.$t("Passport.SignUp") }}
        </div>

        <div class="bottomHit">
          <p class="lostpassword" @click="changeType">
            {{ $my_vue.$t("Passport.PleaseSignin") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HasIconInput from "common/input/HasIconInput";
import User from "model/User.js";
import changeUserState from "@/common/addFun/disposeUserState"
export default {
  data() {
    return {
      //注册数据
      registerData: {
        // userMobile: "",
        userEmail: "",
        userPassword: "",
        userConfirmPassword: "",
        userVcode: "",
      },

      //是否显示提示信息
      isshowhitinfo: false,
      //提示的文本
      hintInfo: "",

      //图形验证码数据
      captcha: "",
      //captcha返回的verify
      verify: "",
      //captch返回的真实验证码
      code: "",
    };
  },
  props: {
    //是否显示弹框
    isshow: {
      type: Boolean,
    },

    //类型
    type: {
      type: String,
    },
  },
  created() {
    this.getcaptcha();
  },
  computed: {
    $my_vue() {
      return $my_vue;
    },
  },
  components: {
    HasIconInput,
  },

  methods: {
    //切换类型
    changeType() {
      this.$emit("update:type", "login");
    },
    //点击注册按钮
    register() {
      //判断是否有内容为空
      let isEmpty = (() => {
        for (let item in this.registerData) {
          if (this.registerData[item] == "") {
            return {
              name: item,
              res: true,
            };
          }
        }
        return {
          res: false,
        };
      })();

      if (isEmpty.res) {
        return (() => {
          this.isshowhitinfo = true;
          // this.hintInfo = `The ${isEmpty.name.replace("user", "")} is empty.`;
          this.hintInfo = $my_vue.$t("Passport.Validation.isEmpty");
        })();
      }

      //判断邮箱是否正确
      let reg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
      let emailIsTrue = reg.test(this.registerData.userEmail);
      if (!emailIsTrue) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = $my_vue.$t("Passport.Validation.EmailError");
        })();
      }

      //判断两次密码是否一致
      let passwordIsOK =
        this.registerData.userPassword == this.registerData.userConfirmPassword;
      if (!passwordIsOK) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = $my_vue.$t("Passport.Validation.PasswordNotSame");
        })();
      }

      //判断验证码是否一致  toLowerCase( )
      if (
        this.registerData.userVcode.toLowerCase() != this.code.toLowerCase()
      ) {
        return (() => {
          this.isshowhitinfo = true;
          this.hintInfo = $my_vue.$t("Passport.Validation.PasswordNotSame");
        })();
      }

      //发送注册请求
      this.userRegister();
    },

    //请求图形验证码
    getcaptcha() {
      User.Passport.Captcha({
        // data:{
        //     service:"user.captcha"
        // },
        succ: (res) => {
          this.captcha = res.content;
          this.verify = res.verify;
          this.code = res.code;
        },
        fail: (res) => {
          this.isshowhitinfo = true;
        },
      });
    },

    //注册请求
    userRegister() {
      User.Passport.Register({
        data: {
          // username: this.registerData.userMobile,
          username:  this.registerData.userEmail,
          password: this.registerData.userPassword,
          email: this.registerData.userEmail,
          repeat_password: this.registerData.userConfirmPassword,
          vcode: this.registerData.userVcode,
          verify: this.verify,
        },
        succ: (res) => {
          //在本地存储用户注册信息
          this.disposeUserInfo(res);
        },
        fail: (res, all) => {
          $my_vue.$message("error", all.msg);
        },
      });
    },

    //登录或注册成功后处理用户信息
    disposeUserInfo(res) {
      let payload = {
        username: res.username,
        avatar: res.avatar,
        token: res.token,
      };

      if (this.isRemmber) {
        changeUserState.add(payload, "long");
      } else {
        changeUserState.add(payload, "short");
      }

    },
  },
};
</script>
<style lang='scss' scoped>
.myregister {
  margin-top: 1.25rem;
  width: 35vw;
  .register {
    width: 100%;
    box-sizing: border-box;
  }

  .hint {
    color: #71778e;
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .group {
    margin-bottom: 1rem;
  }

  .btn {
    background-color:var(--main-color);
    color: white;
    font-size: 0.875rem;
    display: inline-block;
    padding: 0.4rem 1.5rem;
    border-radius: 0.2rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
  }

  .lostpassword {
    font-size: 0.75rem;
    color:var(--minor-color);
  }

  .reminder {
    font-size: 0.75rem;
    color: #71778e;
    margin-bottom: 0.75rem;
  }

  .captcha {
    display: flex;
    img {
      height: 40px;
      margin-right: 0.75rem;
    }
  }

  .bottomHit {
    display: flex;

    .lostpassword {
      margin-right: 0.4rem;
      font-size: 0.75rem;
      color:var(--minor-color);
      cursor: pointer;
    }
  }
  .hintinfo {
    margin-bottom: 0.9rem;
    border: 1px solid var(--border-color-default);
    padding: 0.5rem;
    font-size: 0.75rem;
    color: #71778e;
    .foo {
      color:var(--text-color-minor);
      margin-right: 0.3rem;
    }
  }
}
</style>