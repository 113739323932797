const debounce = function (func, delay) {
    let timer = null
    let isNow = true
    return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            isNow = true
        }, delay);

        if (isNow) {
            isNow = false
            func.call(this, args)
        }


    }
}


export default debounce