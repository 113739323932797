const gallerylist = {
    state: {
        //当前用户购买商品选择的分类信息
        gallerylist: {
            name: '',
            filter: [],
            cat_id: '',
            path: '',
            page: '',
            keyword: '',
            sort: '',
        },
    },

    mutations: {

        //更新用户的选择的商品分类  重置  更新
        updateGallerylist(state, payload) {

            // 初始化
            if (payload.type == 'init') {
                let obj = payload.data
                for (let key in obj) {
                    state.gallerylist[key] = obj[key]
                }
            }

            // 分类改变
            if (payload.type == 'changeClass') {
                let step = payload.step
                let chooseArr = payload.chooseArr

                //空则删除该项
                if (chooseArr.length == 0) {
                    state.gallerylist.filter = state.gallerylist.filter.filter((item) => {
                        return item.step != step
                    })
                } else {
                    //不为空则更新
                    //判断当前数组中有没有该数据
                    let res = state.gallerylist.filter.find((item) => {
                        return item.step == step
                    })
                    if (res) {
                        state.gallerylist.filter = state.gallerylist.filter.map((item) => {
                            if (item.step == step) {
                                item.chooseArr = chooseArr
                            }
                            return item
                        })
                    } else {
                        state.gallerylist.filter.push({
                            step, chooseArr
                        })
                    }

                }

                // 更新path
                let str = ''
                state.gallerylist.filter.forEach((item) => {
                    let key = item.step
                    let value = item.chooseArr.join(',')
                    str += key + ':' + value + ';'
                })
                state.gallerylist.path = str
            }


        },

        // 为商品分类顶部添加对应的分类描述
        updateFilterDetil(state, payload) {
            //修改filter数据，用于顶部已筛选展示
            state.gallerylist.filter = state.gallerylist.filter.map((item) => {
                if (item.step == payload.step) {
                    item.detail = payload
                }
                return item
            })
        },

        // 改变页码、排序
        updatePageSort(state, payload) {
            state.gallerylist[payload.type] = payload.val
        }

    }
}


export default gallerylist