<template>
  <div class="FooterInfo">
    <div class="footerBox">
      <div class="list">
        <p class="item">1、致力于成为行业的引导者；</p>
        <p class="item">
          2、在制茶领域拥有超过半世纪的经验积累，完整地经历了传统手工制茶、半机械制茶时代；
        </p>
        <p class="item">
          3、全面以有机肥代替化肥，主流茶园施用羊粪，高等级茶园施用豆粕；
        </p>
        <p class="item">
          4、强调茶园的生态，奉行自然野放的哲学；同时积极导入生物手段对虫害进行防控，以生物农药代替传统的化学农药；
        </p>
        <p class="item">
          5、积极推行现代科技在茶业中的应用，利用数学和统计学工具，对茶青鲜叶、制作工艺进行归纳分析，并不断迭代和演进；
        </p>
        <p class="item">
          6、为广大茶友提供客观评茶的场所，确保每一条点评的真实性，公信力自然非同凡响；
        </p>
        <!-- <p class="item">
          7、提供高水准的服务，茶友可随意无理由退换，我司承担所有中间运费；得益于我们几十年对品质的把握，我司茶品的退换比率低于1%；
        </p> -->
      </div>
    </div>

    <div class="copyright">
      <p>
        Copyright&nbsp;@&nbsp;2022&nbsp;BuyChinesetea.com&nbsp;Inc.&nbsp;All&nbsp;rights
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterInfo",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.FooterInfo {
  padding-top:50px;
  font-size: var(--font-size-sm);
  color: var(--text-color-minor);
  max-width: 90%;
  margin: 0 auto;
}

.footerBox {
  border-bottom: 1px solid var(--border-color-default);
  .list {
    .item {
      padding: 10px;
    }
  }
}

.copyright{
    padding:30px 0;
    text-align: center;
}
</style>