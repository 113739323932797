// 结算页路由权限
function check_view(to,from,next){

    //从收银台不可直接回到结算页
    if(from.path.startsWith('/paycenter') && to.path.startsWith('/check')){
        next('/home/index')
    }

}


export { check_view}