const routes = [
    {
        path: '/',

        redirect: '/home/index'

    },

    //商品详情页
    {
        path: '/product/index',
        component: () => import('views/product/index')
    },
    //用户账户页
    {
        path: '/account/index',
        component: () => import('views/my-account/index')
    },
    //购物车页
    {
        path: '/cart/index',
        component: () => import('views/cart/index'),
        meta:{
            needLogin:true
        }

    },
    //结算页
    {
        path: '/check/index',
        component: () => import('views/check/index'),
        meta:{
            needLogin:true
        }
    },
    //收银台
    {
        path: '/paycenter/index',
        component: () => import('views/paycenter/index'),
        meta:{
            needLogin:true
        }
    },
    // 商品列表页
    {
        path: '/gallerylist/index',
        component: () => import('views/gallerylist/index'),
        redirect: '/gallerylist/index/list',
        children: [
            {
                // 商品页
                path: 'list',
                component: () => import('views/gallerylist/child/GalleryMainChild/GalleryMainRightChild/GoodList'),
            }
        ]
    },
    //首页
    {
        path: '/home/index',
        meta: {
            title: 'home'
        },
        component: () => import('views/home/index'),
    },
    //会员中心
    {
        path: '/member',
        component: () => import('views/member/index'),
        redirect: '/member/index',
        meta:{
            needLogin:true
        },
        children: [
            {
                // 会员中心首页
                path: 'index',
                component: () => import('views/member/child/memberRightChild/MemberHome'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 会员中心 我的订单
                path: 'orders',
                component: () => import('views/member/child/memberRightChild/MemberOrder'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 收货地址
                path: 'receiver',
                component: () => import('views/member/child/memberRightChild/MemberReceiver'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 优惠劵
                path: 'coupon',
                component: () => import('views/member/child/memberRightChild/MemberCoupon'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 优惠劵
                path: 'point',
                component: () => import('views/member/child/memberRightChild/MemberPoint'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 余额
                path: 'balance',
                component: () => import('views/member/child/memberRightChild/MemberBalance'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 个人信息
                path: 'info',
                component: () => import('views/member/child/memberRightChild/MemberInfo'),
                meta:{
                    needLogin:true
                }
            },
            {
                // 个人收藏
                path: 'favorite',
                component: () => import('views/member/child/memberRightChild/MemberFavorite'),
                meta:{
                    needLogin:true
                }
            },


        ]
    },

    // 订单详情页
    {
        path: '/ordertail/index',
        component: () => import('views/ordertail/index'),
        meta:{
            needLogin:true
        }
    },

    // 文章列表页
    {
        path: '/article/list',
        component: () => import('views/articleList/index')
    },

    // 文章详情页
    {
        path: '/article/detail',
        component: () => import('views/articleDetail/index')
    },

    //404
    {
        path: '*',
        component: () => import('views/404/404')

    }

]


export default routes