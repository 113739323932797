//获取当前浏览器存储的用户信息
function getUserInfo() {
  let sesToken = sessionStorage.getItem("token")
  let locToken = localStorage.getItem("token")
  if (sesToken) {
      let username = sessionStorage.getItem("username")
      let avatar = sessionStorage.getItem("avatar")
      return {
          token: sesToken,
          username,
          avatar
      }
  }
  else if (locToken) {
      let username = localStorage.getItem("username")
      let avatar = localStorage.getItem("avatar")
      return {
          token: locToken,
          username,
          avatar
      }
  } else {
      return false
  }
}

let res = getUserInfo()
if(res){
  var {username,avatar,token} = res
}else{
    var {username,avatar,token} = {username:'',avatar:'',token:''}
}


const user = {
    state: {
    //用户名  头像  凭证
    username: username,
    avatar: avatar,
    token: token,
    },

    mutations: {
        //更新用户信息
        updateUserInfo(state, payload) {
            state.username = payload.username
            state.avatar = payload.avatar
            state.token = payload.token
          },
  
    },
}


export default user