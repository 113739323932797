<template>
  <transition name="slide-fade" appear>
    <div class="dialog_useLoginOrGegister" v-if="isshow">
      <div class="content">
        <h2 class="header">
          {{ title }}
        </h2>
        <login
          v-if="type == 'login'"
          :isshow.sync="isshow"
          :type.sync="type"
        ></login>
        <my-register
          v-if="type == 'register'"
          :type.sync="type"
          :isshow.sync="isshow"
        ></my-register>
        <find-password
          v-if="type == 'findPassword'"
          :type.sync="type"
          :isshow.sync="isshow"
        >
        </find-password>
        <i class="iconfont icon-guanbi close" @click="close"></i>
      </div>
    </div>
  </transition>
</template>
<script>
import Login from "./Login.vue";
import MyRegister from "./MyRegister.vue";
import FindPassword from "./FindPassword.vue";
export default {
  data() {
    return {
      isshow: true,
    };
  },
  computed: {
    title() {
      switch (this.type) {
        case "login":
          return $my_vue.$t("Passport.SignIn");
        case "register":
          return $my_vue.$t("Passport.SignUp");
        case "findPassword":
          return $my_vue.$t("Passport.Pages.FindPwd");
      }
    },
  },

  methods: {
    close() {
      this.isshow = false;
      setTimeout(() => {
        this.$destroy();
      }, 100);
    },
  },

  created() {},

  components: {
    Login,
    MyRegister,
    FindPassword,
  },
};
</script>
<style lang='scss' scoped>
.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 99;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .header {
    color:#333;
    font-size: 1.25rem;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1rem;
    cursor: pointer;
  }
}

.dialog_useLoginOrGegister {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: -100vw;
  top: -100vh;
  right: -100vw;
  bottom: -100vh;
  z-index: 95;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: scale(0.5, 0.5);
  opacity: 0;
}
</style>