import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'

import gallerylist from './modules/gallerylist.js'
import cart from './modules/cart.js'
import user from './modules/user.js'
import member from './modules/member.js'
import common_data from './modules/common_data.js'
import setting from './modules/setting.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    gallerylist,
    cart,
    user,
    member,
    common_data,
    setting
  }
})
